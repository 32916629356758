import {CalculationItem} from './form';

const calculationsArray: CalculationItem[] = [{
  id: "calcPregnancyGestationalAge",
  link: 'all',
  terms: [{
    id: "termProcedureDate",
    field: "question4-procedureDate",
    operator: "filled",
  },{
    id: "termDueDate",
    field: "question117-dueDate",
    operator: "filled",
  }],
  actions: [{
    id: "gestationalAge",
    type: "CALCULATION",
    equation: 'Math.max(Math.round(40 - (new Date(`{question117-dueDate}`) - new Date(`{question4-procedureDate}`)) / (3600000 * 24 * 7)), 0)',
    operands: ['question117-dueDate','question4-procedureDate'],
    resultField: 'question117-gestationalAgeSurgeryWeeks'
  }],
},

{
  id: "isLocalAnesthesia",
  link: 'all',
  terms: [{
    "id": "localAnesthesia",
    "field": "questionLocalAnesthesia",
    "operator": "equals",
    "value": "True"
  }],
  actions: [{
    id: "skipBloodProducts",
    type: "PAGE_SKIP",
    page: "page48",
  },{
    id: "skipBloodMatch",
    type: "PAGE_SKIP",
    page: "page49",
  },{
    id: "skipMedicationScreen",
    type: "PAGE_SKIP",
    page: "pageMedicationScreen",
  },{
    id: "showParagraph",
    type: "VISIBILITY",
    field: "pLocalAnesthesia",
    visibility: "show",
    priority: 10,
  },{
    id: "showNoneLocal",
    type: "VISIBILITY",
    field: "pTestNoneLocalAnesthetic",
    visibility: "show",
    priority: 10,
  },{
    id: "hideNone",
    type: "VISIBILITY",
    field: "pTestNone",
    visibility: "hide",
    priority: 10,
  },{
    id: "hideTestHCG",
    type: "VISIBILITY",
    field: "pTestHCG",
    visibility: "hide",
    priority: 10,
  },{
    id: "hideTestCBC",
    type: "VISIBILITY",
    field: "pTestCBC",
    visibility: "hide",
    priority: 10,
  },{
    id: "hideTestGroupScreen",
    type: "VISIBILITY",
    field: "pTestGroupScreen",
    visibility: "hide",
    priority: 10,
  },{
    id: "hideTestECG",
    type: "VISIBILITY",
    field: "pTestECG",
    visibility: "hide",
    priority: 10,
  },{
    id: "hideTestRenal",
    type: "VISIBILITY",
    field: "pTestRenal",
    visibility: "hide",
    priority: 10,
  },{
    id: "hideTestPTT",
    type: "VISIBILITY",
    field: "pTestPTT",
    visibility: "hide",
    priority: 10,
  },{
    id: "hideTestLiver",
    type: "VISIBILITY",
    field: "pTestLiver",
    visibility: "hide",
    priority: 10,
  },{
    id: "hideTestGlucose",
    type: "VISIBILITY",
    field: "pTestGlucose",
    visibility: "hide",
    priority: 10,
  },{
    id: "hideTestHbA1C",
    type: "VISIBILITY",
    field: "pTestHbA1C",
    visibility: "hide",
    priority: 10,
  },{
    id: "hideTestCXR",
    type: "VISIBILITY",
    field: "pTestCXR",
    visibility: "hide",
    priority: 10,
  },{
    id: "hideTestTSH",
    type: "VISIBILITY",
    field: "pTestTSH",
    visibility: "hide",
    priority: 10,
  },{
    id: 'hidePreopGeneral',
    type: "VISIBILITY",
    field: "paragraphPrepGeneral",
    visibility: "hide"
  },{
    id: 'hidePreopAnesthesiology',
    type: "VISIBILITY",
    field: "paragraphAnesthesiology",
    visibility: "hide"
  }]
},

{
  id: "hideGyn",
  link: "all",
  terms: [{
    id: "termGynecological",
    field: "qGender",
    operator: "equals",
    value: "Male"
  }],
  actions: [{
    id: "hideGyn",
    type: "VISIBILITY",
    visibility: "hide",
    field: "questionGynecologicalProcedure"
  }]
},

{
  id: "setHepaticFailure",
  link: "all",
  terms: [{
    id: "termHepaticDisease",
    field: "question93",
    operator: "equals",
    value: "False"
  },{
    id: "termHepaticFailure",
    field: "question94",
    operator: "empty",
  }],
  actions: [{
    id: "setHepaticFailure",
    type: "CALCULATION",
    operands: [],
    equation: "`False`",
    resultField: "question94",
  }]
},

// Duplicate Vitals
{
  id: "vitals#weight",
  link: 'all',
  terms: [{
    id: "vital#weight",
    field: "qVitals-weight",
    operator: "filled"
  }],
  actions: [{
    id: "setvital#weight",
    type: "CALCULATION",
    equation: "{qVitals-weight}",
    operands: ["qVitals-weight"],
    resultField: "question120-weight"
  }]
},
{
  id: "vitals#height",
  link: 'all',
  terms: [{
    id: "vital#height",
    field: "qVitals-height",
    operator: "filled"
  }],
  actions: [{
    id: "setvital#height",
    type: "CALCULATION",
    equation: "{qVitals-height}",
    operands: ["qVitals-height"],
    resultField: "question120-height"
  }]
},
{
  id: "vitals#bmi",
  link: 'all',
  terms: [{
    id: "vital#bmi",
    field: "qVitals-bmi",
    operator: "filled"
  }],
  actions: [{
    id: "setvital#bmi",
    type: "CALCULATION",
    equation: "{qVitals-bmi}",
    operands: ["qVitals-bmi"],
    resultField: "question120-bmi"
  },{
    id:"setStopbang#bmi",
    type:"CALCULATION",
    equation:"{qVitals-bmi} > 35 ? `Yes` : `No`",
    operands: ["qVitals-bmi"],
    resultField: "question36-bmiOver35"
  }]
},
{
  id: "vitals#neck_circumference",
  link: 'all',
  terms: [{
    id: "vital#neck_circumference",
    field: "qVitals-neck_circumference",
    operator: "filled"
  }],
  actions: [{
    id: "setvital#neck_circumference",
    type: "CALCULATION",
    equation: "`{qVitals-neck_circumference}`",
    operands: ["qVitals-neck_circumference"],
    resultField: "question120-neck_circumference"
  }]
},
{
  id: "vitals#heartrate",
  link: 'all',
  terms: [{
    id: "vital#heartrate",
    field: "qVitals-heartrate",
    operator: "filled"
  }],
  actions: [{
    id: "setvital#heartrate",
    type: "CALCULATION",
    equation: "`{qVitals-heartrate}`",
    operands: ["qVitals-heartrate"],
    resultField: "question120-heartrate"
  }]
},
{
  id: "vitals#bloodpressure",
  link: 'all',
  terms: [{
    id: "vital#bloodpressure",
    field: "qVitals-bloodpressure",
    operator: "filled"
  }],
  actions: [{
    id: "setvital#bloodpressure",
    type: "CALCULATION",
    equation: "`{qVitals-bloodpressure}`",
    operands: ["qVitals-bloodpressure"],
    resultField: "question120-bloodpressure"
  }]
},
{
  id: "vitals#respirationrate",
  link: 'all',
  terms: [{
    id: "vital#respirationrate",
    field: "qVitals-respirationrate",
    operator: "filled"
  }],
  actions: [{
    id: "setvital#respirationrate",
    type: "CALCULATION",
    equation: "`{qVitals-respirationrate}`",
    operands: ["qVitals-respirationrate"],
    resultField: "question120-respirationrate"
  }]
},
{
  id: "vitals#spo2",
  link: 'all',
  terms: [{
    id: "vital#spo2",
    field: "qVitals-spo2",
    operator: "filled"
  }],
  actions: [{
    id: "setvital#spo2",
    type: "CALCULATION",
    equation: "`{qVitals-spo2}`",
    operands: ["qVitals-spo2"],
    resultField: "question120-spo2"
  }]
},
{
  id: "vitals#temperature",
  link: 'all',
  terms: [{
    id: "vital#temperature",
    field: "qVitals-temperature",
    operator: "filled"
  }],
  actions: [{
    id: "setvital#temperature",
    type: "CALCULATION",
    equation: "`{qVitals-temperature}`",
    operands: ["qVitals-temperature"],
    resultField: "question120-temperature"
  }]
},


// Calculat BMI
{
  id: "calcvitals#bmi",
  link: 'all',
  terms: [{
    id: "vitals#bmi#weight",
    field: "qVitals-weight",
    operator: "filled",
  },{
    id: "vitals#bmi#height",
    field: "qVitals-height",
    operator: "filled",
  }],
  actions: [{
    id: "setvitalsbmi",
    type: "CALCULATION",
    equation: '({qVitals-weight} / ({qVitals-height}/100) ** 2) >> 0',
    operands: ["qVitals-weight","qVitals-height"],
    resultField: "qVitals-bmi"
  },{
    id: "setphysicalbmi",
    type: "CALCULATION",
    equation: '({qVitals-weight} / ({qVitals-height}/100) ** 2) >> 0',
    operands: ["qVitals-weight","qVitals-height"],
    resultField: "question120-bmi"
  },{
    id: "setstopbangbmi",
    type: "CALCULATION",
    equation: '(({qVitals-weight} / ({qVitals-height}/100) ** 2) >> 0) > 35 ? `Yes` : `No`',
    operands: ["qVitals-weight","qVitals-height"],
    resultField: "question36-bmiOver35"
  },
  {
    id: "setphysicalBMIExtremes",
    type: "CALCULATION",
    equation: '{qVitals-bmi} >= 40 ? `Morbidly Obese` : {qVitals-bmi} <= 18.5 ? `Underweight` : `Normal`',
    operands: ["qVitals-bmi"],
    resultField: 'questionBMIExtremes'
  }]
},
{
  id: "calc#bmi",
  link: 'all',
  terms: [{
    id: "bmi#weight",
    field: "question120-weight",
    operator: "filled",
    },{
    id: "bmi#height",
    field: "question120-height",
    operator: "filled",
    }
  ],
  actions: [{
    id: "setbmi",
    type: "CALCULATION",
    equation: '({question120-weight} / ({question120-height}/100) ** 2) >> 0',
    operands: ['question120-weight','question120-height'],
    resultField: 'question120-bmi',
  },
  {
    id: "setBMIExtremes",
    type: "CALCULATION",
    equation: '{question120-bmi} >= 40 ? `Morbidly Obese` : {question120-bmi} <= 18.5 ? `Underweight` : `Normal`',
    operands: ["question120-bmi"],
    resultField: 'questionBMIExtremes'
  }]
},
{
  id: "calc#bmiExtremes",
  link: 'all',
  terms: [{
    id: "termBMI",
    field: "question120-bmi",
    operator: "filled",
  }],
  actions: [{
    id: "setBMIExtremes",
    type: "CALCULATION",
    equation: '{question120-bmi} >= 40 ? `Morbidly Obese` : ({question120-bmi} <= 18.5 ? `Underweight` : `Normal`)',
    operands: ["question120-bmi"],
    resultField: 'questionBMIExtremes'
  }]
},
{
  id: "calcSevereMalnourishment",
  link: "all",
  terms: [{
    id: "termBmiDescription",
    field: "questionBMIExtremes",
    operator: "equals",
    value: "Underweight"
  }],
  actions: [{
    id: "showMalnourishment",
    type: "VISIBILITY",
    visibility: "show",
    field: "questionSevereMalourishment"
  }]
},
{
  id: "calc#stopbang",
  link: 'all',
  terms: [
    {
      id: "stopbang#snoring",
      field: "question36-snoring",
      operator: "filled"
    },
    {
      id: "stopbang#tired",
      field: "question36-tired",
      operator: "filled"
    },
    {
      id: "stopbang#apnea",
      field: "question36-apnea",
      operator: "filled"
    },
    {
      id: "stopbang#blood_pressure",
      field: "question36-blood_pressure",
      operator: "filled"
    },
    {
      id: "stopbang#bmiOver35",
      field: "question36-bmiOver35",
      operator: "filled"
    },
    {
      id: "stopbang#ageOver50",
      field: "question36-ageOver50",
      operator: "filled"
    },
    {
      id: "stopbang#neckCircumferenceOver40cm",
      field: "question36-neckCircumferenceOver40cm",
      operator: "filled"
    },
    {
      id: "stopbang#male",
      field: "question36-male",
      operator: "filled"
    },
  ],
  actions: [{
    id: "setStopbangScore",
    type: "CALCULATION",
    equation: "([`{question36-snoring}`,`{question36-tired}`,`{question36-apnea}`,`{question36-blood_pressure}`,`{question36-bmiOver35}`,`{question36-ageOver50}`,`{question36-neckCircumferenceOver40cm}`,`{question36-male}`].reduce((a,b) => a+(b===`Yes`),0)) >= 5 ? `True` : `False`",
    operands: [
      'question36-snoring',
      'question36-tired',
      'question36-apnea',
      'question36-blood_pressure',
      'question36-bmiOver35',
      'question36-ageOver50',
      'question36-neckCircumferenceOver40cm',
      'question36-male',
    ],
    resultField: 'question37',
  }]
},

{
  id: "visCardiacRecent",
  link: "all",
  terms: [{
    id: "termcardiac", field: "question61", operator: "filled"
  }],
  actions: [{
    id: 'showCardiacRecent',
    type: "VISIBILITY",
    field: "questionCardiacRecent",
    visibility: "show"
  }]
},

{
  id: "calcRequiredGerd",
  link: "any",
  terms: [{id: "termGerd",field: "question97",operator: "equals", "value": "False"}],
  actions: [{
    id: 'showGerdCheck',
    type: "VISIBILITY",
    field: "question130-gerd",
    visibility: "show"
  },{
    id: 'hideCheckNone',
    type: "VISIBILITY",
    field: "question130-none",
    visibility: "hide"
  }]
},
{
  id: "calcRequiredInhaler",
  link: "any",
  terms: [{id: "termInhaler",field: "question33",operator: "equals", "value": "False"}],
  actions: [{
    id: 'showInhalerCheck',
    type: "VISIBILITY",
    field: "question130-inhalers",
    visibility: "show"
  },{
    id: 'hideCheckNone',
    type: "VISIBILITY",
    field: "question130-none",
    visibility: "hide"
  }]
},
{
  id: "calcRequiredGlucose",
  link: "any",
  terms: [{id: "termGlucose",field: "question71",operator: "equals", "value": "False"}],
  actions: [{
    id: 'showGlucoseCheck',
    type: "VISIBILITY",
    field: "question130-glucose",
    visibility: "show"
  },{
    id: 'hideCheckNone',
    type: "VISIBILITY",
    field: "question130-none",
    visibility: "hide"
  }]
},
{
  id: "calcRequiredSmoking",
  link: "any",
  terms: [{id: "termSmoking",field: "question26",operator: "equals", "value": "Smoker"}],
  actions: [{
    id: 'showSmokingCheck',
    type: "VISIBILITY",
    field: "question130-smoking",
    visibility: "show"
  },{
    id: 'hideCheckNone',
    type: "VISIBILITY",
    field: "question130-none",
    visibility: "hide"
  }]
},
{
  id: "calcRequiredIron",
  link: "any",
  terms: [{id: "termIron",field: "question80",operator: "equals", "value": "True"}],
  actions: [{
    id: 'showIronCheck',
    type: "VISIBILITY",
    field: "question130-iron",
    visibility: "show"
  },{
    id: 'hideCheckNone',
    type: "VISIBILITY",
    field: "question130-none",
    visibility: "hide"
  }]
},
{
  id: "calcRequiredHtn",
  link: "any",
  terms: [{id: "termHtn",field: "question42",operator: "equals", "value": "False"}],
  actions: [{
    id: 'showHtnCheck',
    type: "VISIBILITY",
    field: "question130-htn",
    visibility: "show"
  },{
    id: 'hideCheckNone',
    type: "VISIBILITY",
    field: "question130-none",
    visibility: "hide"
  }]
},
{
  id: "calcRequiredMurmur",
  link: "any",
  terms: [{id: "termMurmur",field: "question124",operator: "equals", "value": "True"}],
  actions: [{
    id: 'showMurmurCheck',
    type: "VISIBILITY",
    field: "question130-murmur",
    visibility: "show"
  },{
    id: 'hideCheckNone',
    type: "VISIBILITY",
    field: "question130-none",
    visibility: "hide"
  }]
},
{
  id: "calcRequiredExSmoker",
  link: "any",
  terms: [{id: "termSmoker",field: "question26",operator: "equals", "value": "Ex-Smoker"}],
  actions: [{
    id: 'showMurmurCheck',
    type: "VISIBILITY",
    field: "question27b",
    visibility: "show"
  }]
},

{
  id: "calcStopbang",
  link: "all",
  terms: [
    {id:"termNeck",field:"qVitals-neck_circumference",operator: "filled"},
    {id:"termDOB",field:"question1-dob",operator: "filled"},
    {id:"termGender",field:"qGender",operator: "filled"},
  ],
  actions: [{
    id: 'setNeckStopbang',
    type:"CALCULATION",
    operands:["qVitals-neck_circumference"],
    equation:"Number(`{qVitals-neck_circumference}`) > 40 ? `Yes` : `No`",
    resultField: "question36-neckCircumferenceOver40cm"
  },{
    id: "setAgeStopbang",
    type:"CALCULATION",
    operands:['question1-dob'],
    equation:"((new Date(Date.now() - new Date(`{question1-dob}`).valueOf()).getUTCFullYear() - 1970) > 50) ? `Yes` : `No`",
    resultField: "question36-ageOver50",
  },{
    id: "setGenderStopbang",
    type:"CALCULATION",
    operands:['qGender'],
    equation:"`{qGender}` === `Male` ? `Yes` : `No`",
    resultField:"question36-male"
  }]
},


{
  id: "visRequiredTests",
  link: "all",
  terms: [{
    id: "termConsult",
    operator: "equals",
    value: "true",
    field: "calcPreadmission"
  }],
  actions: [{
    id: "hideNoConsult",
    type: "VISIBILITY",
    field: "paragraphRequiredTestsNoConsult",
    visibility: "hide"
  },{
    id: "showConsult",
    type: "VISIBILITY",
    field: "paragraphRequiredTestsConsult",
    visibility: "show"
  }]
},


{
  id: "visHCG",
  link: "all",
  terms: [
    {id: "termGynoProcedure", field: "questionGynecologicalProcedure",operator: "equals", "value": "True"},
    {id: "patientAgeOver50", field: "question1-dob", operator: "ageOver", value: "50"}
  ],
  actions: [{
    id: 'showHCG',
    type: "VISIBILITY",
    field: "pTestHCG",
    visibility: "show"
  },
  {
    id: "hideNone",
    type: "VISIBILITY",
    field: "pTestNone",
    visibility: "hide"
  }]
},
{
  id: "visHighRisk",
  link: "all",
  terms: [
    {id: "termhighrisk", field: "questionHighRiskSurgery",operator: "equals", "value": "True"},
  ],
  actions: [{
    id: 'showCBC',
    type: "VISIBILITY",
    field: "pTestCBC",
    visibility: "show"
  },{
    id: 'showGroupScreen',
    type: "VISIBILITY",
    field: "pTestGroupScreen",
    visibility: "show"
  },
  {
    id: "hideNone",
    type: "VISIBILITY",
    field: "pTestNone",
    visibility: "hide"
  }]
},
{
  id: "vicTestECG",
  link: "any",
  terms: [
    {id: "termArrhythmias", field: "question56",operator: "equals", "value": "True"},
    {id: "termPacemaker", field: "question59",operator: "equals", "value": "True"},
    {id: "termAngina", field: "question43",operator: "equals", "value": "True"},
    {id: "termCardiacADL", field: "question47",operator: "equals", "value": "True"},
    {id: "termMyocardialInfarction", field: "question48",operator: "equals", "value": "True"},
    {id: "termCongestiveHeartFailure", field: "question49",operator: "equals", "value": "True"},
    {id: "termPCIorStents", field: "question51",operator: "equals", "value": "True"},
    {id: "termCABG", field: "question53",operator: "equals", "value": "True"},
    {id: "termValvularDisease", field: "question54",operator: "equals", "value": "True"},
    {id: "termValvularSurgery", field: "question55",operator: "equals", "value": "True"},
    {id: "termPeripheralVascular", field: "questionPeripheralVascular",operator: "equals", "value": "True"},
    {id: "termPulmonaryVascular", field: "questionPulmonaryVascular",operator: "equals", "value": "True"},
    {id: "termOSASevere", field: "question38",operator: "equals", "value": "True"},
    {id: "termSeizure", field: "questionSeizure",operator: "equals", "value": "True"},
    {id: "termStroke", field: "questionStrokeTIA",operator: "equals", "value": "True"},
    {id: "termAgeOver69", field: 'question1-dob', operator: 'ageGTE', 'value': "69"},
    {id: "termChemo", field: "questionChemotherapy",operator: "equals", "value": "True"},
    {id: "termDigoxin", field: "questionChemotherapy",operator: "includes", "value": "Digoxin"},
    {id: "termDiabetes", field: "questionDiabetesDiagnosedPastYear",operator: "equals", "value": "True"},
  ],
  actions: [{
    id: 'showECG',
    type: "VISIBILITY",
    field: "pTestECG",
    visibility: "show"
  },
  {
    id: "hideNone",
    type: "VISIBILITY",
    field: "pTestNone",
    visibility: "hide"
  }]
},
{
  id: "vicCopdAdl",
  link: "all",
  terms: [
    {id: "termCOPD", field: "question29",operator: "equals", "value": "True"},
    {id: "termADL", field: "question32",operator: "equals", "value": "True"},
  ],
  actions: [{
    id: 'showECG',
    type: "VISIBILITY",
    field: "pTestECG",
    visibility: "show"
  },
  {
    id: 'showCBC',
    type: "VISIBILITY",
    field: "pTestCBC",
    visibility: "show"
  },
  {
    id: 'showCXR',
    type: "VISIBILITY",
    field: "pTestCXR",
    visibility: "show"
  },
  {
    id: "hideNone",
    type: "VISIBILITY",
    field: "pTestNone",
    visibility: "hide"
  }]
},
{
  id: "showCBC2",
  link: "any",
  terms: [
    {id: "termPulmonaryVascular", field: "questionPulmonaryVascular",operator: "equals", "value": "True"},
    {id: "termOSASevere", field: "question38",operator: "equals", "value": "True"},
    {id: "termAgeOver69", field: 'question1-dob', operator: 'ageGTE', 'value': "69"},
    {id: "termLowHemoglobin", field: "question80",operator: "equals", "value": "True"},
    {id: "termCoagulopathy", field: "questionCoagulopathy",operator: "equals", "value": "True"},
    {id: "termRenal", field: "question90",operator: "equals", "value": "True"},
    {id: "termHepatic", field: "question93",operator: "equals", "value": "True"},
    {id: "termCancer", field: "question103",operator: "equals", "value": "True"},
    {id: "termAnticoagulant", field: "question24",operator: "equals", "value": "True"},
  ],
  actions: [{
    id: 'showCBC',
    type: "VISIBILITY",
    field: "pTestCBC",
    visibility: "show"
  },
  {
    id: "hideNone",
    type: "VISIBILITY",
    field: "pTestNone",
    visibility: "hide"
  }]
},
{
  id: "showRenalPanel",
  link: "any",
  terms: [
    {id: "termRenal", field: "question90",operator: "equals", "value": "True"},
    {id: "termDiabetes", field: "question68",operator: "equals", "value": "True"},
    {id: "termChemo", field: "questionChemotherapy",operator: "equals", "value": "True"},
    {id: "termHepatic", field: "question93",operator: "equals", "value": "True"},
    {id: "termEndocrine", field: "question77",operator: "equals", "value": "True"},
    {id: "termMedication", field: "question119",operator: "filled"},
    {id: "termAnticoagulant", field: "question24",operator: "equals", "value": "True"},
    {id: "termSteroids", field: "question78",operator: "equals", "value": "True"},
  ],
  actions: [{
    id: 'showRenal',
    type: "VISIBILITY",
    field: "pTestRenal",
    visibility: "show"
  },
  {
    id: "hideNone",
    type: "VISIBILITY",
    field: "pTestNone",
    visibility: "hide"
  }]
},
{
  id: "showPTT",
  link: "any",
  terms: [
    {id: "termCoagulopathy", field: "questionCoagulopathy",operator: "equals", "value": "True"},
    {id: "termChemo", field: "questionChemotherapy",operator: "equals", "value": "True"},
    {id: "termHepatic", field: "question93",operator: "equals", "value": "True"},
    {id: "termAnticoagulant", field: "question24",operator: "equals", "value": "True"},
  ],
  actions: [{
    id: 'showPTT',
    type: "VISIBILITY",
    field: "pTestPTT",
    visibility: "show"
  },
  {
    id: "hideNone",
    type: "VISIBILITY",
    field: "pTestNone",
    visibility: "hide"
  }]
},
{
  id: "showLiver",
  link: "any",
  terms: [
    {id: "termHepatic", field: "question93",operator: "equals", "value": "True"},
  ],
  actions: [{
    id: 'showLiver',
    type: "VISIBILITY",
    field: "pTestLiver",
    visibility: "show"
  },
  {
    id: "hideNone",
    type: "VISIBILITY",
    field: "pTestNone",
    visibility: "hide"
  }]
},
{
  id: "showGlucose",
  link: "any",
  terms: [
    {id: "termDiabetes", field: "question68",operator: "equals", "value": "True"},
    {id: "termSteroids", field: "question78",operator: "equals", "value": "True"},
  ],
  actions: [{
    id: 'showGlucose',
    type: "VISIBILITY",
    field: "pTestGlucose",
    visibility: "show"
  },
  {
    id: "hideNone",
    type: "VISIBILITY",
    field: "pTestNone",
    visibility: "hide"
  }]
},
{
  id: "showHbA1C",
  link: "any",
  terms: [
    {id: "termDiabetes", field: "question68",operator: "equals", "value": "True"},
  ],
  actions: [{
    id: 'showHbA1C',
    type: "VISIBILITY",
    field: "pTestHbA1C",
    visibility: "show"
  },
  {
    id: "hideNone",
    type: "VISIBILITY",
    field: "pTestNone",
    visibility: "hide"
  }]
},
{
  id: "showCXR",
  link: "any",
  terms: [
    {id: "termPulmonaryVascular", field: "questionPulmonaryVascular",operator: "equals", "value": "True"},
    {id: "termOSASevere", field: "question38",operator: "equals", "value": "True"},
    {id: "termCancer", field: "question103",operator: "equals", "value": "True"},
  ],
  actions: [{
    id: 'showCXR',
    type: "VISIBILITY",
    field: "pTestCXR",
    visibility: "show"
  },
  {
    id: "hideNone",
    type: "VISIBILITY",
    field: "pTestNone",
    visibility: "hide"
  }]
},
{
  id: "showTSH",
  link: "any",
  terms: [
    {id: "termThyroid", field: "question73",operator: "equals", "value": "True"},
  ],
  actions: [{
    id: 'showTSH',
    type: "VISIBILITY",
    field: "pTestTSH",
    visibility: "show"
  },
  {
    id: "hideNone",
    type: "VISIBILITY",
    field: "pTestNone",
    visibility: "hide"
  }]
},

{
  id: "showPreopGenAneFunction",
  link: "any",
  terms: [
    {id: "termWalkUphill", field: "functionalAssessment-walkUpHill",operator: "equals", "value": "No"},
    {id: "termHeavyLifting", field: "functionalAssessment-heavyLifting",operator: "equals", "value": "No"},
    {id: "termPushWalking", field: "functionalAssessment-pushWalking",operator: "equals", "value": "No"},
    {id: "termStrenuousActivities", field: "functionalAssessment-strenuousActivities",operator: "equals", "value": "No"},
  ],
  actions:[{
    id: 'showPreopGeneral',
    type: "VISIBILITY",
    field: "paragraphPrepGeneral",
    visibility: "show"
  },{
    id: 'showPreopAnesthesiology',
    type: "VISIBILITY",
    field: "paragraphAnesthesiology",
    visibility: "show"
  }]
},

{
  id: "showPreopGeneral",
  link: "any",
  terms: [
    {id: "termAnticoagulant", field: "question24",operator: "equals", "value": "True"},
    {id: "termADL", field: "question32",operator: "equals", "value": "True"},
    {id: "termLungCondition", field: "question33",operator: "equals", "value": "False"},
    {id: "termHypertension", field: "question42",operator: "equals", "value": "False"},
    {id: "termAnginaRest", field: "question44",operator: "equals", "value": "At Rest"},
    {id: "termAnginaMinimal", field: "question44",operator: "equals", "value": "Minimal exertion"},
    {id: "termAnginaOver10", field: "question44",operator: "equals", "value": "Angina signs and symptoms last more than 10 minutes"},
    {id: "termCardiacOpt", field: "question46",operator: "equals", "value": "False"},
    {id: "termCardiacADL", field: "question47",operator: "equals", "value": "True"},
    {id: "termEjectionFraction", field: "questionEjectionFraction",operator: "equals", "value": "True"},
    {id: "termStentsRecent", field: "qStents12Months",operator: "equals", "value": "True"},
    {id: "termCardiacValveSurgery", field: "question55",operator: "equals", "value": "True"},
    {id: "termCardiacArrhytmia", field: "question56",operator: "equals", "value": "True"},
    {id: "termPacemaker", field: "question59",operator: "equals", "value": "True"},
    {id: "termDiabetes", field: "question69",operator: "equals", "value": "Type 1"},
    {id: "termInsulin", field: "question70",operator: "equals", "value": "True"},
    {id: "termDiabetesControlled", field: "question71",operator: "equals", "value": "False"},
    {id: "termSteroids", field: "question78",operator: "equals", "value": "True"},
    {id: "termAnemia", field: "question80b",operator: "equals", "value": "Workup Pending"},
    {id: "termBloodBorne", field: "question89",operator: "equals", "value": "False"},
    // {id: "termASAhigh", field: "question119",operator: "includes", "value": "ASA high"},
  ],
  actions: [{
    id: 'showPreopGeneral',
    type: "VISIBILITY",
    field: "paragraphPrepGeneral",
    visibility: "show"
  }]
},
{
  id: "showPreopGeneral2",
  link: "threshold",
  threshold: 2,
  terms: [
    {id: "termCoronaryArtery", field: "questionCoronaryArteryDisease",operator: "equals", "value": "True"},
    {id: "termCongestiveHeart", field: "question49",operator: "equals", "value": "True"},
    {id: "termSeizure", field: "questionSeizure",operator: "equals", "value": "True"},
    {id: "termStroke", field: "questionStrokeTIA",operator: "equals", "value": "True"},
    {id: "termDiabetes", field: "question69",operator: "filled"},
    {id: "termRenal", field: "question90",operator: "equals", "value": "True"},
  ],
  actions: [{
    id: 'showPreopGeneral',
    type: "VISIBILITY",
    field: "paragraphPrepGeneral",
    visibility: "show"
  }]
},
{
  id: "showPreopAnesthesiology",
  link: "any",
  terms: [
    {id: "termGeneral1", field: "showPreopGeneral",operator: "equals", "value": "true"},
    {id: "termGeneral2", field: "showPreopGeneral2",operator: "equals", "value": "true"},

    {id: "termAnesthesiaDifficult", field: "complicationDifficultIntubationAirway",operator: "equals", "value": "True"},
    {id: "termAnesthesiaAwake", field: "complicationAwakeIntubation",operator: "equals", "value": "True"},
    {id: "termAnesthesiaHyperthermia", field: "complicationMalignantHyperthermia",operator: "equals", "value": "True"},
    {id: "termAnesthesiaDeficiency", field: "complicationPseudocholinesteraseDeficiency",operator: "equals", "value": "True"},
    {id: "termAnesthesiaTeeth", field: "complicationHistoryOfBrokenTeethAfterAnesthesia",operator: "equals", "value": "True"},
    {id: "termAnesthesiaOther", field: "complicationOther",operator: "equals", "value": "True"},
    {id: "termAnesthesiaRegional", field: "complicationRegionalAnesthesia",operator: "equals", "value": "True"},
    {id: "termDialysis", field: "question92",operator: "equals", "value": "True"},
    {id: "termLiver", field: "question94",operator: "equals", "value": "True"},
    {id: "termSeizureRecent", field: "questionSeizureRecent",operator: "equals", "value": "True"},
    {id: "termStrokeRecent", field: "questionStrokeRecent",operator: "equals", "value": "True"},
    {id: "termOSASevere", field: "question38",operator: "equals", "value": "True"},
    {id: "termNeuro", field: "question65",operator: "equals", "value": "True"},
    {id: "termThyroid", field: "question76",operator: "equals", "value": "False"},
    {id: "termGoitre", field: "question75",operator: "equals", "value": "True"},
    {id: "termCoagulopathy", field: "questionCoagulopathy",operator: "equals", "value": "True"},
    {id: "termAnemia", field: "question80",operator: "equals", "value": "True"},
    {id: "termBloodBorne", field: "question89",operator: "equals", "value": "True"},
    {id: "termRheumatologic", field: "question98",operator: "equals", "value": "True"},
    {id: "termPsychiatric", field: "question102",operator: "equals", "value": "False"},
    {id: "termCancerSitu", field: "question104",operator: "equals", "value": "True"},
    {id: "termAlcoholAbuse", field: "question109",operator: "equals", "value": "True"},
    {id: "termHighBMI", field: "question120-bmi",operator: "greaterThan", "value": "50"},
    {id: "termLowBMI", field: "question120-bmi",operator: "lessThan", "value": "18.5"},
    // {id: "termMalnourishment", field: "questionSevereMalourishment",operator: "equals", "value": "True"},
    {id: "termTranslator", field: "question115",operator: "equals", "value": "True"},
    {id: "termDisability", field: "questionMentalDisability",operator: "equals", "value": "True"},
    {id: "termGestationalAge", field: "question117-gestationalAgeSurgeryWeeks",operator: "greaterOrEqual", "value": "27"},
    {id: "termChronicPain", field: "questionChronicPain",operator: "equals", "value": "True"},
    // {id: "termASAhigh", field: "question119",operator: "includes", "value": "ASA high"},
  ],
  actions: [{
    id: 'showPreopAnesthesiology',
    type: "VISIBILITY",
    field: "paragraphAnesthesiology",
    visibility: "show"
  }]
},
{
  id: "showPreopAnesthesiology2",
  link: "all",
  terms: [
    {id: "termBloodProducts", field: "question81",operator: "equals", "value": "False"},
    {id: "termHighRisk", field: "questionHighRiskSurgery",operator: "equals", "value": "True"},
  ],
  actions: [{
    id: 'showPreopAnesthesiology',
    type: "VISIBILITY",
    field: "paragraphAnesthesiology",
    visibility: "show"
  }]
},
{
  id: "showMustHoldGLT",
  link: "any",
  terms: [
    {id: "termglp", field: "question119", operator: "includes", "value": "GLP-1"},
  ],
  actions: [{
    id: 'showMustHoldGLTaction',
    type: "VISIBILITY",
    field: "questionMustHoldSpecificGLP1",
    visibility: "show"
  }]
},
{
  id: "showMustHoldSGLT",
  link: "any",
  terms: [
    {id: "termsglt", field: "question119", operator: "includes", "value": "SGLT-2"},
  ],
  actions: [{
    id: 'showMustHoldSGLTAction',
    type: "VISIBILITY",
    field: "questionMustHoldSpecificSGLT2",
    visibility: "show"
  }]
},
{
  id: "calcPreadmission",
  link: "any",
  terms: [
    {id: "isLocalAnesthesia", field: "isLocalAnesthesia", operator: "equals", value: "false", required: true},
    {id: "showedAnes", field: "showPreopAnesthesiology",operator: "equals", "value": "true"},
    {id: "showedAnes2", field: "showPreopAnesthesiology2",operator: "equals", "value": "true"},
    {id: "showedGeneral", field: "showPreopGeneral",operator: "equals", "value": "true"},
    {id: "showedGeneral2", field: "showPreopGeneral2",operator: "equals", "value": "true"},
    {id: "showedPreopGenAnes", field: "showPreopGenAneFunction", operator: "equals", value: "true"},
  ],
  actions: [{
    id: 'showPreadmissionVisit',
    type: "VISIBILITY",
    field: "paragraphConsultsGenerated",
    visibility: "show"
  },
  {
    id: 'hideNoPreadmissionVisit',
    type: "VISIBILITY",
    field: "paragraphConsultsNotGenerated",
    visibility: "hide"
  },
  {
    id: 'hideMustHold',
    type: "VISIBILITY",
    field: "questionMustHold",
    visibility: "hide"
  },
  {
    id: 'hideMustTake',
    type: "VISIBILITY",
    field: "questionMustTake",
    visibility: "hide"
  },
  {
    id: "hidePreopNone",
    type: "VISIBILITY",
    field: "paragraphPrepNone",
    visibility: "hide"
  }]
},
];

export default calculationsArray;
