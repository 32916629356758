import React from 'react';
import './Header.scss';

export default function Header(props:{hideTitle?: boolean}) {
  return (
    <header>
      <div className="centered">
        <div className="Logos">
          <a href="https://www.saskhealthauthority.ca/" target="_blank" rel="noreferrer">
            <img src="/logos/logo.png" alt="Sask Health Authority logo" />
          </a>
          <a href="https://medicine.usask.ca/" target="_blank" rel="noreferrer">
            <img src="/logos/uofs-logo.png" alt="U of S logo" />
          </a>
        </div>
        {!props.hideTitle && <div className="Title noprint">
          Quality Improvement Project
        </div>}
      </div>
    </header>
  );
}
