import { FieldsItem, FormValues } from "./data/form";
import data from "./data/DOAForm";
import "./Complete.scss";

function ClinicContact (props: {title: string,phone: string, fax: string}) {
  const {title, phone, fax} = props;
  return <li style={{display:'flex',justifyContent:'space-between'}}>
    <div style={{flex: 1}}>{title}</div><div style={{marginRight: "2rem"}}>{fax}(f)</div><div>{phone}(p)</div>
  </li>
}

const clinicContacts: {[hospitalKey:string]:{title:string,phone:string,fax:string}} = {
  // "RUH_pre":{ title:"RUH Preadmission Clinic", phone:"306-655-1711", fax:"306-655-1710"},
  "RUH":{ title:"RUH Day Surgery", phone:"306-655-2124", fax:"306-655-6792"},
  "SCH_PAC":{ title:"SCH PAC", phone:"306-655-8307", fax:"306-655-8821"},
  "SCH":{ title:"SCH Day Surgery ", phone:"306-655-8733", fax:"306-655-7404"},
  // "SPH_pre":{ title:"SPH Preadmission Clinic", phone:"306-655-5286", fax:"306-655-5897"},
  "SPH":{ title:"SPH Day Surgery", phone:"306-655-5270", fax:"306-655-5627"},
  "JPCH":{ title: "JPCH Maternal Outpatient Clinic", phone: "306-655-1895", fax:"306-655-1453"},
  "Prairieview Surgical Centre":{ title:"Prairieview Surgical Centre (PSC)", phone:"306-955-3282", fax:"306-955-3292"},
  "Medical Arts Surgicentre":{ title:"Medical Arts Building", phone:"306-665-6277", fax:"306-665-6275"},
};

type CompleteProps = {
  onBack: ()=>void,
  formValues: FormValues,
  showField: (id:string)=>boolean,
  result?: boolean,
  onPrint: ()=>any,
  onQuit?: ()=>any,
  printed?: boolean,
}

export default function Complete (props:CompleteProps) {
  const preadmission = props.showField('paragraphConsultsGenerated');
  const clinic = String(props.formValues['questionSurgerySite']);
  const labReqs = !props.showField('pTestNone');
  const contact = props.formValues['calcPreadmission'] === "true" 
    ? clinicContacts["SCH_PAC"]
    : clinicContacts[clinic] || clinicContacts["SCH"];

  const consultRequired = props.formValues["question131a"] === "True";
  const qConsults = data.find((x) => x.id === "questions131b") as FieldsItem;
  const rConsults = qConsults?.questions
    .filter((item) => {
      const value = (props.formValues[qConsults.id+'-'+item.key]);
      return typeof value === "string" && !!value.trim();
    })
    .map((item) => {
      const key = qConsults.id + "-" + item.key
      const value = props.formValues[key] as string;
      return `${item.resultTitle || item.title}: ${value.trim()}`
  }).join(", ");

  const showMedReminder =
    (props.showField('questionMustHoldSpecificGLP1') || props.showField('questionMustHoldSpecificSGLT2'))
    && consultRequired;
  
  return <div className={props.result ? 'ResultModal' : 'ModalContainer'}>
    <div className='Complete'>
      <h1>FINAL INSTRUCTIONS</h1>
      <p className="noprint">These instructions are also included in the printout</p>
      <ol>
        <li><b>PRINT</b> off the "CLEAR-OP Summary" {!preadmission && ' and "Instructions for Patient" from this program'}</li>
        <li><b>ADD</b> patient medical <b>STICKERS</b> to the printout{preadmission?'':'s'}</li>
        <li><b>SIGN</b> the printed CLEAR-OP Summary</li>
        <li><b>PRINT</b> off your office version of <i>Complete Medical History</i> as well as any requested <i>Investigations/Test Results</i></li>
        {contact ? (
          <li>
            <b>FAX</b> all documents to {contact.title} <u>{contact.fax}</u>. Call <u>{contact.phone}</u> if questions
          </li>
        ):(
          <li><b>FAX</b> to the respective hospital Day Surgery, Same Day Admit, or Preadmission Clinic if the patient is being referred for consultation</li>
        )}
        {!preadmission && <li><b>Provide</b> patient with the "Instructions for Patient" form {labReqs && " as well as a completed Laboratory Test Requisition"}</li>}
        {labReqs && <li><b>Provide</b> patient with a completed <i>Laboratory Test Requisition</i> and <b>request</b> it is <b>done prior to the {preadmission ? 'PAC appointment':'day of surgery'}</b></li>}
        {consultRequired && <li><b>REMIND</b> patient to schedule a review with <i>{rConsults}</i></li>}
        {showMedReminder && <li><b>Remind patient to stop their medications as required in case their PAC appointment is scheduled after the recommended stopping date for the medication</b> (ie 3 weeks for GLP-1 Agonist, 3 days for SGLT-2 inhibitor)</li>}
      </ol>
      {!contact && (
      <p>
        <u>Contact numbers</u>:
        {Object.values(clinicContacts).map((c) => {
          return <ClinicContact key={c.title} title={c.title} phone={c.phone} fax={c.fax} />
        })}
      </p>
      )}
      {props.printed && (
        <div>
          <p>Thank you for completing the CLEAR-OP Assessment</p>
          <p>You are helping to make surgery safer for your patient!</p>
        </div>
      )}
      <div className="CompleteNav">
        <button className='button' onClick={props.onBack}>Back</button>
        <button className='button' onClick={props.onPrint}>Print</button>
        {props.onQuit && <button className='button' onClick={props.onQuit} disabled={!props.printed}>Quit</button>}
      </div>
    </div>
  </div>;
}
