import { CalculationItem, FormCalcAction, FormCalcTerm, FormValue, GetFormValue, SetFormValue } from "./data/form";

class Calculations {
  calculationsArray: CalculationItem[];
  calculations: { [calculationId: string]: CalculationItem; };
  /** Contains term ids that link to array of calculation ids it is part of */
  terms: { [termId: string]: string[]; };
  targets: { [targetFieldId: string]: string[]; };
  pages: { [pageId: string]: string[]; };
  state: { [calculationId: string]: string};

  constructor(calculationsArray: CalculationItem[]) {
    const calc = Calculations.CalculationsObject(calculationsArray);
    this.calculationsArray = calculationsArray;
    this.calculations = calc.calculations;
    this.terms = calc.terms;
    this.targets = calc.targets;
    this.pages = calc.pages;
    this.state = {};
  }

  static CalculationsObject(calculationsArray:CalculationItem[]) {
    const calculations:{[calculationId:string]:CalculationItem} = {};
    const terms:{[termFieldId:string]: string[]} = {};
    const targets:{[targetFieldId:string]: string[]} = {};
    const pages:{[pageId:string]:string[]} = {};
    for (let calculation of calculationsArray) {
      calculations[calculation.id] = calculation;
      calculation?.terms?.forEach((term) => {
        if (!terms[term.field]) {
          terms[term.field] = [calculation.id];
        } else {
          terms[term.field].push(calculation.id);
        }
      });
      calculation?.actions?.forEach((action) => {
        if (action.type === "VISIBILITY") {
          if (!targets[action.field]) targets[action.field] = [];
          targets[action.field].push(calculation.id);
        } else if (action.type === "PAGE_SKIP") {
          if (!pages[action.page]) pages[action.page] = [];
          pages[action.page].push(calculation.id);
        }
      });
    }
    return {
      calculations,
      terms,
      targets,
      pages,
    };
  }

  static ActionResult(action: FormCalcAction, getValue: GetFormValue) {
    let equation = action.equation;
    for (let operand of action.operands) {
      let value = getValue(operand);
      if (typeof value !== "string") {
        value = JSON.stringify(value);
      }
      value = value.replaceAll("`", "'");
  
      equation = equation.replaceAll(`{${operand}}`, value);
    }
    // eslint-disable-next-line no-eval
    return eval(equation);
  }

  static CheckTerms(calculation: CalculationItem, getValue: GetFormValue, setValue: SetFormValue|undefined = undefined) {
    const passed = Calculations._CheckTerms(calculation, getValue);
    setValue?.(calculation.id, String(passed), -2);
    return passed;
  }
  static _CheckTerms(calculation: CalculationItem, getValue: GetFormValue) {
    if (!calculation.terms?.length) {
      return true;
    }
  
    if (calculation.link === "all" || calculation.link === "any") {
      const isAll = calculation.link === "all"
      for (let term of calculation.terms) {
        const value = getValue(term.field);
        const passed = Calculations.CheckTerm(term, value);
        // Required terms are ignored for any/all
        if (term.required) {
          if (!passed) {
            return false;
          }
        } else if (isAll === !passed) {
          return !isAll;
        }
      }
      return isAll;
    } else if (calculation.link === "threshold") {
      let count = 0;
      for (let term of calculation.terms) {
        const value = getValue(term.field);
        const passed = Calculations.CheckTerm(term, value);
        // @ts-ignore
        count += passed;
      }
      return count >= calculation.threshold;
    }
    return false;
  }

  static getAge(dob: FormValue, nowDate?: FormValue) {
    if (typeof dob !== "string") {
      return 0;
    }
    if (nowDate && typeof nowDate !== "string") {
      return 0;
    }
    const now = nowDate ? new Date(nowDate) : new Date();
    const date = new Date(dob);
    if (isNaN(now.valueOf()) || isNaN(date.valueOf())) {
      return 0;
    }
    let age = now.getUTCFullYear() - date.getUTCFullYear();
    const monthDiff = now.getUTCMonth() - date.getUTCMonth();

    if (monthDiff < 0) {
      age -= 1;
    } else if (monthDiff === 0 && now.getUTCDate() < date.getUTCDate()) {
      age -= 1;
    }
    return age;
  }

  static CheckTerm(term: FormCalcTerm, value: FormValue) {
    switch (term.operator) {
      case 'includes':
        return value?.includes(String(term.value));
      case 'ageGTE':
        return Calculations.getAge(value) >= Number(term.value);
      case 'ageOver':
        return Calculations.getAge(value) > Number(term.value);
      case 'filled':
        return !!(value?.length ?? value);
      case 'empty':
        return !value || value?.length === 0;
      case 'exists':
        return value != null;
      case 'equals':
        return value === term.value;
      case 'greaterThan':
        return Number(value) > Number(term.value);
      case 'lessThan':
        return Number(value) < Number(term.value);
      case 'greaterOrEqual':
        return Number(value) >= Number(term.value);
      case 'lessOrEqual':
        return Number(value) <= Number(term.value);
      default:
        return false;
    }
  }

  skipPage(pageID:string, getValue:GetFormValue) {
    const queries = this.pages[pageID];
    if (!queries) return false;
    const shouldSkip = queries.reduce((skip:boolean, calcID) => {
      const calc = this.calculations[calcID];
      const passed = Calculations.CheckTerms(calc, getValue);
      this.state[calcID] = String(passed);
      return skip || passed;
    }, false)
    return shouldSkip
  }

  hideField(targetId:string, getValue: GetFormValue, setValue: SetFormValue|undefined = undefined) {
    const calcIds = this.targets[targetId];
    type VisibilityState = {value?:boolean, priority:number};
    const state: {hide:VisibilityState,show:VisibilityState}= {
      hide: {
        value: undefined,
        priority: Number.MIN_SAFE_INTEGER,
      },
      show: {
        value:undefined,
        priority: Number.MIN_SAFE_INTEGER,
      }
    };
    const calcGetValue:GetFormValue = (fieldID) => {
      const v = getValue(fieldID);
      const c = this.state[fieldID];
      return v ?? c;
    }
    calcIds?.forEach((calcId) => {
      const calculation = this.calculations[calcId];
      const checkedTerms = Calculations.CheckTerms(calculation, calcGetValue, setValue);
      this.state[calcId] = String(checkedTerms);
      calculation.actions?.forEach((action) => {
        if (action.type === "VISIBILITY" && action.field === targetId) {
          const cur = state[action.visibility];
          if (cur.priority <= (action.priority ?? 0)) {
            state[action.visibility].value = !!cur.value || checkedTerms;
          }
        }
      });
    });
    // TODO: is this necessary?
    // if (state.hide.priority > state.show.priority) {
    //   return state.hide.value === true;
    // }
    // if (state.hide.priority < state.show.priority) {
    //   return state.show.value === false;
    // }
    return state.hide.value === true || state.show.value === false;
  }

  checkEventChange(targetId:string, getValue: GetFormValue, setValue: SetFormValue) {
    const calcIds = this.terms[targetId];
    calcIds?.forEach((calcId) => {
      const calculation = this.calculations[calcId];
      const checkedTerms = Calculations.CheckTerms(calculation, getValue, setValue);
      this.state[calcId] = String(checkedTerms);
      if (checkedTerms) {
        calculation.actions?.forEach((action) => {
          try {
            if (action.type === "CALCULATION") {
              const x = Calculations.ActionResult(action, getValue);
              setValue(action.resultField, String(x));
            }
          } catch (err) {
            console.log(err);
          }
        });
      }
    });
  }
}

export default Calculations;
