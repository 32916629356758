import {FormItemTypes} from "./form";

const DOAForm: FormItemTypes[] = [
  {
    "type":"SECTION_HEADER",
    "hideResult": true,
    "id":"sectionHealthCareProviderInformation",
    "title":"Health Care Provider Information"
  },
  {
    "id":"question136",
    "hideResult": true,
    "title":"Designation",
    "type":"RADIO",
    "options":["Family Physician","Nurse Practitioner"],
    "hasOtherOption": true,
    "otherOptionTitle": "Other"
  },
  {
    "id":"question138",
    "hideResult": true,
    "title":"Health Care Provider's Contact Information",
    "type":"FIELDS",
    "resultPrependTitles": false,
    // "resultTitle": "Contact Information",
    "questions":[
      {"key":"name", "title":"Name"},
      {"key":"clinic", "title":"Clinic"},
      {"key":"phone", "title":"Phone Number", "inputMode": "tel"}
    ]
  },

  // TODO: insert Patient Data page
  {
    "type": "PAGE_BREAK",
    "id": "pagePatientData"
  },
  {
    "type": "SECTION_HEADER",
    "id": "sectionPatientData",
    "title": "Patient Data"
  },
  {
    "id":"question1",
    "hideResult": true,
    "title":"Surgical Candidate",
    "type":"FIELDS",
    "questions":[
      {
        "key": "dob",
        "title": "Date of Birth",
        "type": "date",
        "pattern": "[0-9]{4}/(0[1-9]|1[0-2])/(0[1-9]|[12][0-9]|3[01])",
        "placeholder": "YYYY/MM/DD"
      }
    ]
  },
  {
    "id":"qGender",
    "hideResult": true,
    "title":"Gender Status",
    "type": "RADIO",
    "options": [
      {
        "title": "Male",
      },
      {
        "title": "Female",
      },
      {
        "title": "Non-binary"
      }
    ]
  },
  {
    "id":"qVitals",
    "title":"<b><u>Measurements/Vital Signs</u></b>",
    "type":"FIELDS",
    "hideResult": true,
    "questions":[
      {
        "key": "weight",
        "type": "number",
        "inputMode": "decimal",
        "title": "Weight: (kg)",
      },{
        "key": "height",
        "type": "number",
        "inputMode": "decimal",
        "title": "Height: (cm)",
      },{
        "key": "bmi",
        "type": "number",
        "inputMode": "decimal",
        "title": "BMI: (kg/m2)",
      },{
        "key": "neck_circumference",
        "type": "number",
        "inputMode": "decimal",
        "title": "Neck Circumference: (cm)",
      },{
        "key": "heartrate",
        "title": "HR: (beats/min)",
      },{
        "key": "bloodpressure",
        "title": "BP: (SBP/DBP mmHg)",
      },{
        "key": "respirationrate",
        "title": "RR: (breaths/min)",
      },{
        "key": "spo2",
        "title": "SpO2: (%)",
      },{
        "key": "temperature",
        "title": "Temp: (Celsius)",
      }
    ]
  },

  {
    "type": "PAGE_BREAK",
    "id": "pageSurgicalProcedure",
  },
  {
    "type": "SECTION_HEADER",
    "id": "sectionSurgicalProcedure",
    "title": "Surgical Procedure"
  },
  {
    "id":"question5",
    "hideResult": true,
    "title":"Today's Date",
    "type":"FIELDS",
    "questions":[
      {
        "key": "todaysDate",
        "title": "Date",
        "type": "date",
        "pattern": "[0-9]{4}/(0[1-9]|1[0-2])/(0[1-9]|[12][0-9]|3[01])",
        "placeholder": "YYYY/MM/DD"
      }
    ]
  },
  {
    "id":"question3",
    "hideResult": true,
    "title":"Surgical Procedure",
    "type":"FIELDS",
    "questions":[
      {"key":"surgical_procedure", "title": "Surgical Procedure"},
      {"key":"surgeon","title":"Surgeon"}
    ]
  },
  {
    "id":"question4",
    "hideResult": true,
    "title":"Date of Surgical Procedure",
    "type":"FIELDS",
    "questions":[
      {
        "key": "procedureDate",
        "title": "Date",
        "type": "date",
        "pattern": "[0-9]{4}/(0[1-9]|1[0-2])/(0[1-9]|[12][0-9]|3[01])",
        "placeholder": "YYYY/MM/DD"
      }
    ]
  },
  {
    "id":"questionSurgerySite",
    "hideResult": true,
    "title":"Proposed Site of Surgery",
    "type":"RADIO",
    "options":["RUH", "SCH", "SPH", "JPCH", "Prairieview Surgical Centre","Medical Arts Surgicentre"]
  },
  {
    "id": "questionHighRiskSurgery",
    "hideResult": true,
    "type": "BOOLEAN",
    "title": "Does the proposed surgical procedure appear on the High Risk Surgery list? (<a id='high-risk' href='#high-risk'>Saskatchewan High Risk Surgeries</a>)"
  },
  {
    "id": "questionGynecologicalProcedure",
    "hideResult": true,
    "type": "BOOLEAN",
    "title": "Is this surgery a gynecological procedure? (does not include obstetrics or gyne-oncology)"
  },
  {
    "id": "questionLocalAnesthesia",
    "hideResult": true,
    "type": "BOOLEAN",
    "title": "Is this procedure being performed under Local/Topical Anesthesia or Eye Block? (ie. cataracts, retinal surgery, superficial skin excision, etc). &nbsp;Surgeon will indicate this on the booking/request form that you receive from their office."
  },
  {
    "id": "pLocalAnesthesia",
    "type": "PARAGRAPH",
    "body": "<b>For this type of procedure, no lab tests are required and the patient will NOT be referred to the Preadmission Clinic for consultation</b>"
  },


  {
    "type": "PAGE_BREAK",
    "title": "",
    "id": "page9",
    "hideResult": true,
    "pageNavigationType": "CONTINUE"
  },
  {
    "type": "SECTION_HEADER",
    "title": "Drug Allergies or Sensitivities",
    "id": "sectionDrugAllergies"
  },
  {
    "id": "question22",
    "title": "Does the patient have any <strong><u>KNOWN</u> Drug Allergies or Sensitivities?</strong>",
    "resultTitle": "Drug Allergies/Sensitivities",
    "type": "BOOLEAN",
    "falseTitle": "No/Unknown",
    "onFalse":{"pageNavigationType": "GO_TO_PAGE", "goToPage": "page11"}
  },
  

  {
    "type": "PAGE_BREAK",
    "title": "",
    "id": "page10"
  },
  {
    "id": "question23",
    "title": "List <strong>ALL <u>KNOWN</u> DRUG ALLERGIES OR SENSITIVITIES</strong> with details <i>if known</i>:",
    "type": "FIELDS",
    "resultTitle": "Drug Allergies/Sensitivities",
    "required": false,
    "questions":[{"key":"drug_1","title":"Drug 1: "},{"key":"drug_2","title":"Drug 2: "},{"key":"drug_3","title":"Drug 3: "},{"key":"drug_4","title":"Drug 4: "},{"key":"drug_5","title":"Drug 5: "},{"key":"drug_6","title":"Drug 6: "},{"key":"drug_7","title":"Drug 7: "},{"key":"drug_8","title":"Drug 8: "},{"key":"drug_9","title":"Drug 9: "},{"key":"drug_10","title":"Drug 10: "},{"key":"drug_11","title":"Drug 11: "},{"key":"drug_12","title":"Drug 12: "},{"key":"drug_13","title":"Drug 13: "},{"key":"drug_14","title":"Drug 14: "},{"key":"drug_15","title":"Drug 15: "},{"key":"drug_16","title":"Drug 16: "},{"key":"drug_17","title":"Drug 17: "},{"key":"drug_18","title":"Drug 18: "},{"key":"drug_19","title":"Drug 19: "},{"key":"drug_20","title":"Drug 20: "}]
  },


  {
    "type": "PAGE_BREAK",
    "title": "",
    "id": "page11"
  },
  {
    "type": "SECTION_HEADER",
    "id": "sectionAnticoagulants",
    "title": "Anticoagulants"
  },
  {
    "id": "question24",
    "hideResult": true,
    "title": "Is the patient taking a prescribed <strong>Anticoagulant/Antiplatelet Medication</strong> (not including Aspirin)?",
    "resultTitle": "Anticoagulant medication",
    "type": "BOOLEAN",
    "onFalse":{"pageNavigationType": "GO_TO_PAGE", "goToPage": "pageSurgicalHistory"}
  },


  {
    "type": "PAGE_BREAK",
    "id": "page12"
  },
  {
    "id": "question25",
    "title": "List details regarding ANTICOAGULANT MEDICATION: ",
    "type": "FIELDS",
    "resultTitle": "Anticoagulant",
    "customRender": "<b>Anticoagulant: {anticoagulantMedication} {dosage} for {indication}</b> - {otherDetails}",
    "questions": [
      {
        "key": "anticoagulantMedication",
        "title": "Anticoagulant Medication",
      },
      {
        "key": "dosage",
        "title": "Dosage"
      },
      {
        "key": "indication",
        "title": "Indication",
      },
      {
        "key": "otherDetails",
        "required": false,
        "title": "Other Details"
      },
    ]
  },

  {
    "type": "PAGE_BREAK",
    "id": "pageSurgicalHistory"
  },
  {
    "type": "SECTION_HEADER",
    "id": "sectionSurgicalHistory",
    "title": "Past Surgical History"
  },
  {
    "type": "BOOLEAN_DETAILS",
    "title": "Has the patient had any Major Surgical Complications in the past?",
    "resultTitle": "Major Complications from Previous Surgery",
    "boldDetails": true,
    "id": "questionSurgicalHistory"
  },
  

  {
    "type":"PAGE_BREAK",
    "id":"page2"
  },
  {
    "type":"SECTION_HEADER",
    "title":"General Anesthesia History",
    "id": "sectionGeneralAnesthesiaHistory"
  },
  {
    "id": "question7",
    "hideResult": true,
    "title":"Has the patient ever had a <strong>Major Complication/Difficulty</strong> with Previous <strong><u>General Anesthesia</u></strong>?",
    "type":"BOOLEAN",
    "resultTitle": "Complication/Difficulty with Previous General Anesthesia",
    "onFalse":{"pageNavigationType":"GO_TO_PAGE","goToPage":"page4"}
  },


  {
    "type": "PAGE_BREAK",
    "title": "",
    "id": "page3"
  },
  {
    "id": "complicationDifficultIntubationAirway",
    "title": "Has the patient ever had a <strong>DIFFICULT INTUBATION/AIRWAY</strong>?",
    "boldDetails": true,
    "resultTitle": "<b>DIFFICULT INTUBATION/AIRWAY</b>",
    "type": "BOOLEAN_DETAILS"
  },
  {
    "id": "complicationAwakeIntubation",
    "title": "Has the patient ever had an <strong>AWAKE INTUBATION</strong>?",
    "resultTitle": "<b>Awake Intubation History</b>",
    "boldDetails": true,
    "type": "BOOLEAN_DETAILS"
  }, 
  {
    "id": "complicationPostoperativeNauseaAndVomiting",
    "title": "Has the patient ever had <strong>POSTOPERATIVE NAUSEA AND VOMITING (PONV)</strong>?",
    "resultTitle": "Postop nausea and vomiting history",
    "type": "BOOLEAN_DETAILS"
  },
  {
    "id": "complicationMalignantHyperthermia",
    "title": "Has the patient ever had <strong>MALIGNANT HYPERTHERMIA</strong>?",
    "resultTitle": "<b>MALIGNANT HYPERTHERMIA</b>",
    "type": "BOOLEAN_DETAILS"
  },
  {
    "id": "complicationPseudocholinesteraseDeficiency",
    "title": "Has the patient ever had <strong>PSEUDOCHOLINESTERASE DEFICIENCY</strong>?",
    "resultTitle": "<b>PSEUDOCHOLINESTERASE DEFICIENCY</b> History",
    "type": "BOOLEAN_DETAILS"
  },
  {
    "id": "complicationHistoryOfBrokenTeethAfterAnesthesia",
    "title": "Has the patient ever had a <strong>HISTORY OF BROKEN TEETH AFTER ANESTHESIA</strong>?",
    "resultTitle": "History of <b>Broken Teeth</b> After Anesthesia",
    "type": "BOOLEAN_DETAILS"
  },
  {
    "id": "complicationOther",
    "title": "Has the patient ever had any <strong>OTHER Complication/Difficulty with Previous General Anesthesia</strong>?",
    "resultTitle": "Complication/Difficulty with Previous General Anesthesia",
    "boldDetails": true,
    "type": "BOOLEAN_DETAILS"
  },


  {
    "type": "PAGE_BREAK",
    "hideResult": true,
    "id": "page4"
  },
  {
    "type": "SECTION_HEADER",
    "id": "sectionRegionalAnesthesiaHistory",
    "title":"Regional Anesthesia History"
  },
  {
    "id": "complicationRegionalAnesthesia",
    "title": "Has the patient ever had a <strong>Major Complication/Difficulty</strong> with<strong> <u>Regional Anesthesia</u> (epidural/spinal, nerve block)?</strong>",
    "resultTitle": "Regional Anesthesia Complication/Difficulty",
    "type": "BOOLEAN",
    "onFalse":{"pageNavigationType":"GO_TO_PAGE","goToPage":"page6"}
  },


  {
    "type": "PAGE_BREAK",
    "id": "page5",
    "pageNavigationType": "CONTINUE"
  },
  {
    "type":"SECTION_HEADER",
    "hideResult": true,
    "id":"sectionRegionalAnesthesiaComplications",
    "title":"Regional Anesthesia Complications"
  },
  {
    "id": "complicationEpidural",
    "title": "Has the patient ever had a <strong>Major Complication/Difficulty</strong> with <strong>EPIDURAL ANESTHESIA</strong>?",
    "resultTitle": "<b>Epidural</b> Anesthesia Complication/Difficulty",
    "boldDetails": true,
    "type": "BOOLEAN_DETAILS"
  },
  {
    "id": "complicationSpinal",
    "title": "Has the patient ever had a <strong>Major Complication/Difficulty</strong> with <strong>SPINAL ANESTHESIA</strong>?",
    "resultTitle": "<b>Spinal</b> Anesthesia Complication/Difficulty",
    "boldDetails": true,
    "type": "BOOLEAN_DETAILS"
  },
  {
    "id": "complicationNerveBlock",
    "title": "Has the patient ever had a <strong>Major Complication/Difficulty</strong> with a <strong>NERVE BLOCK</strong>?",
    "resultTitle": "<b>Nerve Block</b> Complication/Difficulty",
    "boldDetails": true,
    "type": "BOOLEAN_DETAILS"
  },


  {
    "type": "PAGE_BREAK",
    "id": "page6",
    "pageNavigationType": "CONTINUE"
  },
  {
    "type":"SECTION_HEADER",
    "id":"sectionFunctionalAssessment",
    "title":"Function Assessment (M-DASI-4Q)"
  },
  {
    "id":"functionalAssessment",
    "title": "Is the patient:",
    "type":"BOOLEAN_GRID",
    "columns":[
      {"key":"Yes","title":"Yes"},
      {"key":"No","title":"No"}
    ],
    "selectAll": 0,
    "rows":[
      {
        "key": "walkUpHill",
        "title": "Able to climb a flight of stairs or walk up a hill?"
      },
      {
        "key": "heavyLifting",
        "title": "Able to do heavy work around the house (lifting and moving heavy furniture)?"
      },
      {
        "key": "pushWalking",
        "title": "Able to do yard work (raking leaves or pushing a mower)?"
      },
      {
        "key": "strenuousActivities",
        "title": "Able to participate in strenuous sports (swimming, tennis, football, skiing)?"
      },
    ],
    "detailsTitle": "Please provide specific details of Etiology: (if known or suspected) "
  },

  {
    "id": "functionalAdaptedFrom",
    "type": "PARAGRAPH",
    "body": "<u>Adapted from:</u> Thresholds of functional capacity using the four-question (modified) Duke Activity Status Index (M-DASI-4Q) as a screening tool: observations from the Measurement of Exercise Tolerance Before Surgery (METS) study<br/><br/>Chun H.A. Lee et al. &nbsp;<i>2020 British Journal of Anaesthesia</i>. &nbsp;doi: 10.1016/j.bja.2020.11.022"
  },


  {
    "type": "PAGE_BREAK",
    "id": "page13"
  },
  {
    "type": "SECTION_HEADER",
    "id": "sectionSmoking",
    "title": "Smoking History"
  },
  {
    "id": "question26",
    "title": "Is the patient a Smoker or Ex-smoker?",
    "type": "RADIO",
    "options": [
      { "title": "Non-Smoker", "pageNavigationType": "GO_TO_PAGE", "goToPage": "page15", "hideResult": true, "triggerNext": true },
      { "title": "Smoker", "pageNavigationType": "CONTINUE", "goToPage": null },
      { "title": "Ex-Smoker", "pageNavigationType": "CONTINUE", "goToPage": null }
    ]
  },


  {
    "type": "PAGE_BREAK",
    "hideResult": true,
    "id": "page14"
  },
  {
    "id": "question27a",
    "type": "FIELDS",
    "resultTitle": "Pack-years",
    "questions": [
      {"key":"packyrs","title": "Provide # Pack-yrs:"}
    ],
  },
  {
    "id": "question27b",
    "type": "FIELDS",
    "resultTitle": "Quit smoking",
    "questions": [
      {"key": "exyears", "title":"If ex-smoker - how many years?"}
    ],
  },


  {
    "type": "PAGE_BREAK",
    "hideResult": true,
    "id": "page15"
  },
  {
    "type": "SECTION_HEADER",
    "id": "sectionRespiratory",
    "title": "Respiratory"
  },
  {
    "id": "question28",
    "title": "Does the patient have any known <strong>Respiratory Disease/Condition</strong>?",
    "resultTitle": "Respiratory Disease/Condition",
    "type": "BOOLEAN",
    "onFalse":{"pageNavigationType": "GO_TO_PAGE", "goToPage": "page18"}
  },


  {
    "type": "PAGE_BREAK",
    "id": "page16"
  },
  {
    "id": "question29",
    "title": "Does the patient have <strong>COPD</strong>?",
    "resultTitle": "<b>COPD</b>",
    "boldDetails": true,
    "type": "BOOLEAN_DETAILS"
  },
  {
    "id": "question30",
    "title": "Does the patient have <strong>Asthma</strong>?",
    "resultTitle": "<b>Asthma</b>",
    "boldDetails": true,
    "type": "BOOLEAN_DETAILS"
  },
  {
    "id": "question31",
    "title": "Does the patient have any <strong>OTHER Respiratory Disease</strong>?",
    "resultTitle": "Other Respiratory Disease",
    "boldDetails": true,
    "type": "BOOLEAN_DETAILS"
  },
  {
    "id": "question32",
    "title": "Do any of the lung diseases <strong>LIMIT ACTIVITIES of DAILY LIVING (ADLs)</strong>?",
    "resultTitle": "Lung Condition <b>limits activities of daily living (ADLs)</b>",
    "boldDetails": true,
    "detailsTitle": "If YES, please describe or clarify answer",
    "type": "BOOLEAN_DETAILS"
  },
  {
    "id": "question33",
    "title": "Is the lung disease <strong>Medically Optimized</strong>?",
    "detailsOn": "False",
    "boldDetails": true,
    "resultTitle": {
      "False": "Lung disease <b>not medically optimized</b>",
      "True": "Lung disease medically optimized"
    },
    "type": "BOOLEAN_DETAILS"
  },
  {
    "type": "PAGE_BREAK",
    "id": "page17"
  },
  {
    "id": "question34",
    "title": "Does the patient <b><u>currently</u></b> have an <strong>Upper Respiratory Tract Infection</strong>?",
    "resultTitle": "<b>Upper Respiratory Tract Infection</b>",
    "boldDetails": true,
    "detailsTitle": "Please provide details (optional):",
    "type": "BOOLEAN"
  },
  {
    "id": "question35",
    "title": "Does the patient have <strong><u>diagnosed</u> Obstructive Sleep Apnea (OSA)</strong>?",
    "detailsTitle": "Please provide details (optional):",
    "resultTitle": "Obstructive Sleep Apnea",
    "boldDetails": true,
    "onFalse": {"pageNavigationType": "GO_TO_PAGE", "goToPage": "page18"},
    "type": "BOOLEAN"
  },


  {
    "type": "PAGE_BREAK",
    "id": "page19"
  },
  {
    "type": "SECTION_HEADER",
    "id": "sectionOSA",
    "hideResult": true,
    "title": "Obstructive Sleep Apnea (OSA)"
  },
  {
    "id": "question38",
    "title": "Is the <strong>OSA</strong> diagnosed as <strong>Severe</strong>?",
    "resultTitle": "<div class='ResultIndent'></div>Severe <b>OSA</b>",
    "type": "BOOLEAN"
  },
  {
    "id": "question39",
    "title": "CPAP <strong><u>Prescribed</u></strong>?",
    "resultTitle": "<div class='ResultIndent'></div>CPAP Prescribed",
    "type": "BOOLEAN"
  },
  {
    "id": "question40",
    "title": "<strong>Compliant</strong> with prescribed CPAP?",
    "resultTitle": {
      "False": "<div class='ResultIndent'></div>Not Compliant with prescribed CPAP",
      "True": "<div class='ResultIndent'></div>Compliant with prescribed CPAP"
    },
    "onFalse": {"pageNavigationType": "GO_TO_PAGE", "goToPage": "page20"},
    "onTrue": {"pageNavigationType": "GO_TO_PAGE", "goToPage": "page20"},
    "type": "BOOLEAN",
    "detailsTitle": "Comments (optional):"
  },

  {
    "type": "PAGE_BREAK",
    "id": "page18"
  },
  {
    "type": "SECTION_HEADER",
    "id": "sectionSTOPBANG",
    "hideResult": true,
    "title": "Obstructive Sleep Apnea (OSA) Risk Factors (S.T.O.P.B.A.N.G.)"
  },
  {
    "id": "question36",
    "hideResult": true,
    "title": "Please select the OSA (STOPBANG) Risk Factors that apply (Score 1 point for each of the 8 risk factors): ",
    "type": "BOOLEAN_GRID",
    "columns": [
      {"key":"No","title":"No"},
      {"key":"Yes","title":"Yes"}
    ],
    "rows": [
      {
        "key": "snoring",
        "title": "<b>S</b>noring",
      },
      {
        "key": "tired",
        "title": "<b>T</b>ired (abnormally)",
      },
      {
        "key": "apnea",
        "title": "<b>O</b>bserved apnea",
      },
      {
        "key": "blood_pressure",
        "title": "blood <b>P</b>ressure elevated",
      },
      {
        "key": "bmiOver35",
        "title": "<b>B</b>MI >35",
      },
      {
        "key": "ageOver50",
        "title": "<b>A</b>ge >50",
      },
      {
        "key": "neckCircumferenceOver40cm",
        "title": "<b>N</b>eck circumference >40cm",
      },
      {
        "key": "male",
        "title": "<b>G</b>ender - male"
      }
    ]
  },
  {
    "id": "question37",
    "title": "Is OSA Highly Suspected? (STOPBANG Score of 5/8 or higher)",
    "resultTitle": "<b>OSA Highly Suspected</b>",
    "type": "BOOLEAN"
  },


  {
    "type": "PAGE_BREAK",
    "id": "page20"
  },
  {
    "type": "SECTION_HEADER",
    "id": "sectionHypertension",
    "title": "Hypertension"
  },
  {
    "id": "question41",
    "hideResult": true,
    "title": "Does the patient have <strong>Hypertension</strong>?",
    "resultTitle": "Hypertension",
    "type": "BOOLEAN",
    "onFalse":{"pageNavigationType": "GO_TO_PAGE", "goToPage": "page22"},
    "detailsTitle": "Comments (optional):"
  },


  {
    "type": "PAGE_BREAK",
    "id": "page21"
  },
  {
    "id": "question42",
    "title": "Is the Hypertension <u>Controlled</u>?",
    "type": "BOOLEAN_DETAILS",
    "resultTitle": {
      "False": "<b>Hypertension poorly controlled</b>",
      "True": "<b>Hypertension</b> Controlled"
    },
    "boldDetails": true,
    "trueTitle": "Controlled",
    "falseTitle": "Poorly controlled",
    "detailsOn": "False",
    "detailsTitle": "Recent Changes to Treatment: "
  },


  {
    "type": "PAGE_BREAK",
    "hideResult": true,
    "id": "page22"
  },
  {
    "type": "SECTION_HEADER",
    "id": "sectionAngina",
    "title": "Angina"
  },
  {
    "id": "question43",
    "title": "Does the patient experience <strong>Angina</strong>?",
    "resultTitle": "Angina",
    "type": "BOOLEAN",
    "onFalse":{"pageNavigationType": "GO_TO_PAGE", "goToPage": "page24"},
  },


  {
    "type": "PAGE_BREAK",
    "id": "page23"
  },
  {
    "id": "question44",
    "title": "Characterize the Angina Symptoms below (select all that apply):",
    "type": "CHECKBOX",
    "resultTitle": "<b>Angina</b>",
    "boldDetails": true,
    "boldOptions": true,
    "options": ["At Rest", "Minimal exertion", "Moderate exertion", "Strenuous exertion", "Angina signs and symptoms last less than 10 minutes", "Angina signs and symptoms last more than 10 minutes"],
    "detailsTitle": "Provide details (optional):"
  },


  {
    "type": "PAGE_BREAK",
    "id": "page24"
  },
  {
    "type": "SECTION_HEADER",
    "id": "sectionCardiovascularHistory",
    "hideResult": true,
    "title": "Cardiovascular History"
  },
  {
    "id": "question45",
    "title": "Does the patient have any diagnosed <strong>Cardiac Disease/Condition</strong>?",
    "resultTitle": "Cardiac Disease/Condition",
    "boldDetails": true,
    "type": "BOOLEAN",
    "onFalse":{"pageNavigationType": "GO_TO_PAGE", "goToPage": "page35"},
    "detailsTitle": "Comments (optional):"
  },
  {
    "comment": "TODO: where this goes",
    "id": "questionPeripheralVascular",
    "title": "Does the patient have a diagnosis of Peripheral Vascular Disease?",
    "resultTitle": "<b>Peripheral Vascular Disease</b>",
    "boldDetails": true,
    "type": "BOOLEAN_DETAILS",
  },
  {
    "comment": "TODO: where this goes",
    "id": "questionPulmonaryVascular",
    "title": "Does the patient have a diagnosis of Pulmonary Vascular Disease/PHTN?",
    "resultTitle": "<b>Pulmonary Vascular Disease</b>",
    "boldDetails": true,
    "type": "BOOLEAN_DETAILS",
  },


  {
    "type": "PAGE_BREAK",
    "id": "page25"
  },
  {
    "id": "question46",
    "title": "Is the Management of the Cardiac Disease/Condition <u>Optimized</u>?",
    "resultTitle": {
      "True": "<b>Cardiac</b> Disease/Condition Management Optimized",
      "False": "<b>Cardiac</b> Disease/Condition Management <b>NOT Optimized</b>"
    },
    "boldDetails": true,
    "type": "BOOLEAN",
    "detailsTitle": "Comments (optional):"
  },
  {
    "id": "question47",
    "title": "Does the Cardiac Condition <u>Limit ADLs</u>?",
    "resultTitle": "Cardiac Condition <b>Limits ADLs</b>",
    "boldDetails": true,
    "type": "BOOLEAN",
    "detailsTitle": "Provide details or clarify (optional):"
  },

  {
    "type": "PAGE_BREAK",
    "id": "pageCoronaryArteryDisease"
  },
  {
    "id": "questionCoronaryArteryDisease",
    "title": "Does the patient have diagnosed Coronary Artery Disease (CAD)?",
    "resultTitle": "<b>Coronary Artery Disease</b>",
    "type": "BOOLEAN",
    "detailsTitle": "Provide details (optional):"
  },

  {
    "type": "PAGE_BREAK",
    "id": "page26"
  },
  {
    "id": "question48",
    "title": "Has the patient had a previous Myocardial Infarction(MI)?",
    "resultTitle": "Previous <b>Myocardial Infarction</b>",
    "boldDetails": true,
    "type": "BOOLEAN_DETAILS",
    "detailsTitle": "What was the approximate Date?:"
  },
  {
    "id": "question49",
    "title": "Has the patient had Heart Failure?",
    "resultTitle": "Previous <b>Heart Failure</b>",
    "boldDetails": true,
    "type": "BOOLEAN_DETAILS",
    "onFalse":{"pageNavigationType":"GO_TO_PAGE","goToPage":"page28"},
    "detailsTitle": "Please provide specific details:"
  },


  {
    "type": "PAGE_BREAK",
    "id": "page27"
  },
  {
    "id": "question50",
    "title": "Patient with Heart Failure",
    "type": "FIELDS",
    "required": false,
    "resultPrependTitles": true,
    "questions": [
      {
        "key": "lastepisode_date",
        "title": "Date of Last Episode",
        "resultTitle": "Last Episode and Date",
        "pattern": "[0-9]{4}/(0[1-9]|1[0-2])/(0[1-9]|[12][0-9]|3[01])",
        "placeholder": "YYYY/MM",
        "type": "date"
      },
      {
        "key": "echocardiogram_date",
        "title": "Date of Echocardiogram (if available)",
        "resultTitle": "Last Echo and Date",
        "pattern": "[0-9]{4}/(0[1-9]|1[0-2])",
        "placeholder": "YYYY/MM/DD",
        "type": "month"
      }
    ]
  },
  {
      "id": "questionEjectionFraction",
      "title": "Ejection Fraction (%) on latest Echocardiogram (if available):",
      "type": "RADIO",
      "options": [
        {
          "title": "EF > 30%",
          "value": "False",
          "resultTitle": "<b class='FieldItemResult'>EF > 30%</b>"
        },
        {
          "title": "EF < 30%",
          "value": "True",
          "resultTitle": "<b class='FieldItemResult'>EF < 30%</b>"
        },
        {
          "title": "Not available",
          "value": "not_available",
          "hideResult": true,
        }
      ]
  },


  {
    "type": "PAGE_BREAK",
    "id": "page28"
  },
  {
    "id": "question51",
    "title": "Has the patient had any Percutaneous Coronary Intervention (PCI)/Coronary Stents?",
    "resultTitle": "Percutaneous Coronary Intervention <b>(PCI)/Coronary Stents</b>",
    "type": "BOOLEAN",
    "onFalse":{"pageNavigationType":"GO_TO_PAGE","goToPage":"page30"}
  },


  {
    "type": "PAGE_BREAK",
    "id": "page29"
  },
  {
    "id": "question52",
    "title": "Patient with Coronary Stents:",
    "type": "CHECKBOX",
    "boldOptions": true,
    "options": [
      "Drug-Eluting Stent (DES)",
      "Bare Metal Stent (BMS)"
    ]
  },
  {
    "id": "qStentApproxDate",
    "type": "FIELDS",
    "questions": [
      {
        "key": "pci_date",
        "title": "Approximate Date of PCI",
        "pattern": "[0-9]{4}/(0[1-9]|1[0-2])",
        "placeholder": "YYYY/MM",
        "type": "month",
      }
    ],
    "detailsTitle": "Details (optional):"
  },
  {
    "id": "qStents12Months",
    "type": "BOOLEAN",
    "resultTitle": "<div class='ResultIndent'></div>Coronary Stents placed within the last 12 months",
    "title": "Were the Coronary Stents placed <u>within the last 12 months</u>?"
  },

  {
    "type": "PAGE_BREAK",
    "id": "page30"
  },
  {
    "id": "question53",
    "type": "BOOLEAN_DETAILS",
    "title": "Has the patient had a Coronary Artery Bypass Graft (CABG) surgery?",
    "resultTitle": "Coronary Artery Bypass Graft <b>(CABG)</b>",
    "boldDetails": true,
    "detailsRequired": false,
    "detailsTitle": "Provide details if available:"
  },


  {
    "type": "PAGE_BREAK",
    "id": "page31"
  },
  {
    "id": "question54",
    "title": "Does the patient have Cardiac Valvular disease?",
    "resultTitle": "Cardiac Valvular Disease",
    "boldDetails": true,
    "type": "BOOLEAN_DETAILS",
    "detailsTitle": "Please provide specific details: "
  },


  {
    "type": "PAGE_BREAK",
    "id": "page32"
  },
  {
    "id": "question55",
    "title": "Has the patient had Cardiac Valve surgery?",
    "resultTitle": "Cardiac Valve Surgery",
    "boldDetails": true,
    "type": "BOOLEAN_DETAILS",
    "detailsRequired": false,
    "detailsTitle": "Provide details if available:"
  },


  {
    "type": "PAGE_BREAK",
    "id": "page33"
  },
  {
    "id": "question56",
    "title": "Does the patient have any Cardiac Arrhythmias?",
    "resultTitle": "Cardiac Arrhythmias",
    "hideResult": true,
    "type": "BOOLEAN_DETAILS",
    "detailsTitle": "Provide details:",
    "onFalse": {"pageNavigationType": "GO_TO_PAGE", "goToPage":"page35"}
  },


  {
    "type": "PAGE_BREAK",
    "id": "page34"
  },
  {
    "id": "question57",
    "title": "Define the Cardiac Arrhythmia:",
    "boldOptions": true,
    "boldDetails": true,
    "type":"CHECKBOX",
    "options":[
      "Bradyarrhythmia",
      "Ectopic heartbeat (PACs, PVCs)",
      "Supraventricular arrhythmia (Afib, Aflutter, Paroxysmal SVT)",
      "Ventricular arrhythmia (VT, Vfib)"
    ],
    "detailsTitle":"Cardiac Arrhythmia Diagnosis (please specify)"
  },
  {
    "id": "question58",
    "title":"Is the Cardiac Arrhythmia symptomatic (presyncope, syncope, or shortness of breath)?",
    "resultTitle": "<div class='ResultIndent'></div><b>Arrhythmia Symptomatic</b>",
    "boldDetails": true,
    "type":"BOOLEAN",
    "detailsTitle":"Provide details (optional): "
  },
  {
    "id": "question59",
    "title":"Does the patient have a Pacemaker or Implanted Cardiac Defibrillator (ICD)?",
    "resultTitle": "<div class='ResultIndent'></div><b>Pacemaker/Implanted Cardiac Defibrillator (ICD)</b>",
    "boldDetails": true,
    "type":"BOOLEAN",
    "onFalse": {"pageNavigationType":"GO_TO_PAGE","goToPage": "page35"},
    "detailsTitle": "Please provide details/indication (optional):"
  },

  {
    "type": "PAGE_BREAK",
    "id": "pagePacemakerChecked"
  },
  {
    "id": "questionPacemakerChecked",
    "title":"Has the device been interrogated/checked by pacemaker clinic within the last 12 months?",
    "resultTitle": {
      "True": "<div class='ResultIndent'></div>Pacemaker checked in last 12 Months",
      "False": "<div class='ResultIndent'></div>Pacemaker <b>NOT checked in last 12 Months</b>",
    },
    "type":"BOOLEAN",
  },


  {
    "type": "PAGE_BREAK",
    "id": "page35"
  },
  {
    "id": "question60",
    "title": "Has the patient had any Cardiac Tests or Treatments?",
    "hideResult": true,
    "type":"BOOLEAN",
    "onFalse": {"pageNavigationType": "GO_TO_PAGE", "goToPage":"page37"},
  },


  {
    "type": "PAGE_BREAK",
    "id": "page36"
  },
  {
    "type":"SECTION_HEADER",
    "id": "sectionCardiacTestsTreatments",
    "hideResult": true,
    "title":"Cardiac Tests and Treatments"
  },
  {
    // See FormItem paragraph render before changing
    "id": "question61",
    "title":"Please select completed Cardiac Tests or Treatments:",
    "resultTitle": "Cardiac Tests or Treatments",
    "type":"CHECKBOX",
    "boldOptions": true,
    "boldDetails": true,
    "options":[
      "Echocardiogram",
      "Yearly Echocardiograms",
      "Stress Test",
      "Angiogram",
      "Holter",
      "Pacemaker"
    ],
    "required": false,
    "hasOtherOption":true,
    "otherOptionTitle": "Other Cardiac Test or Treatment (describe):"
  },


  {
    "type": "PAGE_BREAK",
    "id": "page37"
  },
  {
    "type":"SECTION_HEADER",
    "id": "sectionNeuroHistory",
    "title":"Neurologic History"
  },

  {
    "comment": "TODO: Should the next two questions be duplicated for seizure and stroke",
    "id": "questionSeizure",
    "resultTitle": "<b>Seizure</b>",
    "boldDetails": true,
    "title": "Has the patient had a Seizure?",
    "type": "BOOLEAN_DETAILS",
    "onFalse": {"pageNavigationType": "GO_TO_PAGE", "goToPage": "pageStrokeTIA"},
    "detailsRequired": false,
    "detailsTitle": "Provide details (optional):"
  },

  {
    "type": "PAGE_BREAK",
    "id": "pageSeizureRecent"
  },
  {
    "comment": "TODO: Verify the next page shouldn't be skipped",
    "id": "questionSeizureRecent",
    "title": "Was the Seizure <u>within the Last 6 Months</u>?",
    "boldDetails": true,
    "resultTitle": "<div class='ResultIndent'></div>Seizure within the <b>Last 6 Months</b>",
    "type": "BOOLEAN"
  },


  {
    "type": "PAGE_BREAK",
    "id": "pageSeizureOptimized"
  },
  {
    "id": "questionSeizureOptimized",
    "title": "Is the Seizure Management <u>Optimized</u>?",
    "boldDetails": true,
    "resultTitle": {
      "True": "<div class='ResultIndent'></div>Seizure management Optimized",
      "False": "<div class='ResultIndent'></div>Seizure management <b>NOT Optimized</b>"
    },
    "detailsOn": "False",
    "type": "BOOLEAN_DETAILS"
  },
  {
    "type": "PAGE_BREAK",
    "id": "pageStrokeTIA"
  },
  {
    "id": "questionStrokeTIA",
    "title": "Has the patient had a Stroke/TIA?",
    "resultTitle": "<b>Stroke/TIA</b>",
    "boldDetails": true,
    "type": "BOOLEAN_DETAILS",
    "detailsRequired": false,
    "detailsTitle": "Provide details (optional):",
    "onFalse": {"pageNavigationType": "GO_TO_PAGE", "goToPage": "page40"}
  },

  {
    "type": "PAGE_BREAK",
    "id": "page38"
  },
  {
    "comment": "TODO: Verify the next page shouldn't be skipped",
    "id": "questionStrokeRecent",
    "title": "Was the Stroke/TIA <u>within the Last 6 Months</u>?",
    "resultTitle": "<div class='ResultIndent'></div>Stroke/TIA within the Last 6 Months",
    "type": "BOOLEAN"
  },


  {
    "type": "PAGE_BREAK",
    "id": "page39"
  },
  {
    "id": "questionStrokeOptimized",
    "title": "Is the Stroke/TIA management <u>Optimized</u>?",
    "resultTitle": {
      "True": "",
      "False": "<div class='ResultIndent'></div>Stroke/TIA management <b>NOT Optimized</b>"
    },
    "boldDetails": true,
    "detailsOn": "False",
    "type": "BOOLEAN_DETAILS"
  },


  {
    "type": "PAGE_BREAK",
    "id": "page40"
  },
  {
    "id": "question65",
    "title": "Does the patient have any Neurologic or Neuromuscular Disease/Disorder?",
    "resultTitle": "Neurologic or Neuromuscular Disease/Disorder",
    "type": "BOOLEAN",
    "onFalse": {"pageNavigationType": "GO_TO_PAGE", "goToPage": "page42"}
  },


  {
    "type": "PAGE_BREAK",
    "id": "page41"
  },
  {
    "id": "question66",
    "title": "What is the specific Diagnosis?",
    "boldOptions": true,
    "options": [
      "MS",
      "Myasthenia Gravis",
      "Myopathy"
    ],
    "type": "CHECKBOX",
    "hasOtherOption": true,
    "otherOptionTitle":"Other, describe:"
  },
  {
    "id": "question67",
    "title": "Neurologic or Neuromuscular Disease/Disorder details:",
    "type":"FIELDS",
    "required": false,
    "resultPrependTitles": true,
    "questions":[
      {"key": "notable_symptoms", "title":"Notable Symptoms/Limitations"},
      {"key": "management_details", "title":"Present Management details"}
    ]
  },


  {
    "type": "PAGE_BREAK",
    "id": "page42"
  },
  {
    "type":"SECTION_HEADER",
    "id": "sectionDiabetes",
    "title":"Diabetes"
  },
  {
    "id": "question68",
    "title": "Does the patient have Diabetes?",
    "hideResult": true,
    "resultTitle": "<u>Diabetes</u>",
    "type": "BOOLEAN",
    "onFalse": {"pageNavigationType": "GO_TO_PAGE", "goToPage": "page44"}
  },


  {
    "type": "PAGE_BREAK",
    "id": "page43"
  },
  {
    "id": "question69",
    "title": "Type of Diabetes",
    "resultTitle": "<b>Diabetes</b>", 
    "type": "RADIO",
    "boldOption": true,
    "options":["Type 1", "Type 2"]
  },
  {
    "id": "questionDiabetesDiagnosedPastYear",
    "title": "Was the diabetes Diagnosed in the Past Year?",
    "resultTitle": "<div class='ResultIndent'></div>Diabetes Diagnosed in Past Year",
    "type": "BOOLEAN"
  },
  {
    "id": "question70",
    "title": "Does the Treatment of Diabetes require Insulin?",
    "resultTitle": "<div class='ResultIndent'></div>Diabetes requires <b>Insulin</b>",
    "type": "BOOLEAN"
  },
  {
    "id": "question71",
    "title": "Is the Diabetes Well-Controlled?",
    "resultTitle": {
      "True": "<div class='ResultIndent'></div>Diabetes well-controlled",
      "False": "<div class='ResultIndent'></div>Diabetes <b>NOT well-controlled</b>"
    },
    "boldDetails": true,
    "type": "BOOLEAN",
    "detailsOn": "False",
    "detailsRequired": true,
    "detailsTitle": "Daily BGM range:"
  },
  {
    "id": "question72",
    "title": "Microvascular/Macrovascular Complications?",
    "resultTitle": "<div class='ResultIndent'></div>Microvascular/Macrovascular Complications",
    "boldDetails": true,
    "type": "BOOLEAN_DETAILS",
    "detailsTitle": "Describe"
  },


  {
    "type": "PAGE_BREAK",
    "id": "page44"
  },
  {
    "type":"SECTION_HEADER",
    "id": "sectionThyroid",
    "title":"Thyroid"
  },
  {
    "id": "question73",
    "title": "Does the patient have Thyroid Disease?",
    "hideResult": true,
    "resultTitle": "<u>Thyroid Disease</u>",
    "type": "BOOLEAN",
    "onFalse": {"pageNavigationType": "GO_TO_PAGE", "goToPage": "page46"}
  },


  {
    "type": "PAGE_BREAK",
    "id": "page45"
  },
  {
    "id": "question74",
    "title": "Define the Thyroid Disease:",
    "type": "RADIO",
    "boldOption": true,
    "options": [
      {
        "title":"Hypothyroidism",
        "resultTitle":"<b>Hypothyroidism</b>",
      },
      {
        "title":"Hashimoto's Disease",
        "resultTitle":"<b>Hashimoto's Disease</b>",
      },
      {
        "title":"Hyperthyroidism",
        "resultTitle":"<b>Hyperthyroidism</b>",
      },
      {
        "title":"Graves Disease",
        "resultTitle":"<b>Graves Disease</b>",
      },
    ],
    "hasOtherOption":true,
    "otherOptionTitle":"Other, provide details:"
  },
  {
    "id": "question75",
    "title": "Does the patient have a Massive Goitre?",
    "resultTitle": "<b>Massive Goitre</b>",
    "type": "BOOLEAN"
  },
  {
    "id": "question76",
    "title": "Is the Thyroid Condition Controlled/Optimized?",
    "resultTitle": {
      "True": "Thyroid Condition Controlled/Optimized",
      "False": "Thyroid Condition <b>NOT Controlled/Optimized</b>"
    },
    "boldDetails": true,
    "detailsOn": "False",
    "type": "BOOLEAN_DETAILS"
  },


  {
    "type": "PAGE_BREAK",
    "id": "page46"
  },
  {
    "type":"SECTION_HEADER",
    "id": "sectionEndocrine",
    "hideResult": true,
    "title":"Endocrine Disease/Disorder"
  },
  {
    "id": "question77",
    "title": "Does the patient have any Other Endocrine Disease?",
    "resultTitle": "Other Endocrine Disease",
    "boldDetails": true,
    "type": "BOOLEAN_DETAILS",
    "detailsTitle": "Please provide specific details: "
  },


  {
    "type": "PAGE_BREAK",
    "id": "page47"
  },
  {
    "type":"SECTION_HEADER",
    "id": "sectionImmuneStatus",
    "hideResult": true,
    "title":"Immune Status"
  },
  {
    "id": "question78",
    "title": "Is the patient on Steroids or Immunosuppressant Medications?",
    "resultTitle": "<b>Steroids or Immunosuppressant Medications</b>",
    "boldDetails": true,
    "type": "BOOLEAN_DETAILS",
    "detailsTitle":"Dose, Duration, and Indication: "
  },

  {
    "id": "pageHemoglobin",
    "type": "PAGE_BREAK",
  },
  {
    "type": "SECTION_HEADER",
    "id": "sectionHematology",
    "title": "Hematology"
  },
  {
    "id": "question80",
    "title": "Does the patient have a Hemoglobin < 130?",
    "resultTitle": "<b>Anemia</b>",
    "hideResult": true,
    "type": "BOOLEAN",
    "onFalse": {"pageNavigationType": "GO_TO_PAGE", "goToPage":"page48"}
  },
  {
    "id": "pageAnemia",
    "type": "PAGE_BREAK",
  },
  {
    "id": "question80b",
    "title": "Does the patient have Anemia?",
    "resultTitle": "<b>Anemia</b>",
    "boldDetails": true,
    "type": "RADIO",
    "options": [
      "Diagnosis known",
      "Workup Pending"
    ],
    "detailsTitle": "Diagnosis:",
    "detailsOn": "Diagnosis known",
    "detailsRequired": true
  },


  {
    "type": "PAGE_BREAK",
    "id": "page48"
  },
  {
    "type":"SECTION_HEADER",
    "id": "sectionBloodProducts",
    "hideResult": true,
    "title":"Blood Products"
  },
  {
    "id": "question81",
    "title": "Is the patient willing to Accept Blood Products?",
    "type": "RADIO",
    "options": [
      {
        "title": "Yes",
        "hideResult": true,
        "value": "True"
      },
      {
        "title": "No",
        "resultTitle": "Patient <b>refuses to accept blood products</b>",
        "value": "False"
      }
    ],
    "detailsOn": "False",
    "detailsRequired": true,
    "detailsTitle": "Provide specific details: "
  },
  {
    "id": "question82",
    "title": "Has the patient had a Prior Blood Transfusion?",
    "falseTitle": "No/Unknown",
    "hideResult": true,
    "type": "BOOLEAN",
    // "onFalse": {"pageNavigationType": "GO_TO_PAGE", "goToPage": "page50"}
  },
  
  
  {
    "type": "PAGE_BREAK",
    "id": "page49"
  },
  {
    "type":"SECTION_HEADER",
    "id": "sectionBloodMatch",
    "hideResult": true,
    "title":"Blood Match"
  },
  {
    "id": "question83",
    "title": "Is there a known presence of Antibodies/Rare Blood Match?",
    "resultTitle": "<b>Presence of Antibodies/Rare Blood Match</b>",
    "falseTitle": "No/Unknown",
    "boldDetails": true,
    "type": "BOOLEAN_DETAILS",
    "detailsTitle": "Provide specific details: "
  },
  
  {
    "type": "PAGE_BREAK",
    "id": "pageHematologicDisease"
  },
  {
    "type": "SECTION_HEADER",
    "id": "sectionHematologicDisease",
    "hideResult": true,
    "title": "Hematologic Disease"
  },
  {
    "id": "question79",
    "title": "Does the patient have any Hematologic disease?",
    "resultTitle": "Hematologic disease",
    "hideResult": true,
    "type": "BOOLEAN",
    "onFalse": {"pageNavigationType": "GO_TO_PAGE", "goToPage": "page50"}
  },

  {
    "type": "PAGE_BREAK",
    "id": "pageCoaguloDisorder"
  },
  {
    "id": "questionCoagulopathy",
    "title": "Does the patient have a Coagulopathy or Hypercoagulable Disorder?",
    "resultTitle": "<b>Coagulopathy/Hypercoagulable disorder</b>",
    "boldDetails": true,
    "type": "BOOLEAN_DETAILS"
  },
  {
    "id": "questionOtherHematologic",
    "title": "Does the patient have any OTHER Hematologic Disease?",
    "resultTitle": "Other Hematologic Disease",
    "boldDetails": true,
    "type": "BOOLEAN_DETAILS"
  },


  {
    "type": "PAGE_BREAK",
    "id": "page50"
  },
  {
    "type":"SECTION_HEADER",
    "id": "sectionBloodBorneDisease",
    "hideResult": true,
    "title":"Blood Borne Disease"
  },
  {
    "id": "question87",
    "title": "Does the patient have a Blood Borne Infection?",
    "hideResult": true,
    "resultTitle": "Blood Borne Infection",
    "type": "BOOLEAN",
    "onFalse":{"pageNavigationType":"GO_TO_PAGE","goToPage":"page52"}
  },


  {
    "type": "PAGE_BREAK",
    "id": "page51"
  },
  {
    "type":"SECTION_HEADER",
    "id": "sectionBloodBorneInfection",
    "title":"Blood Borne Infection"
  },
  {
    "id": "question88",
    "title": "Type of Blood Borne Infection",
    "resultTitle": "Blood Borne Infection",
    "boldOptions": true,
    "type": "CHECKBOX",
    "required": true,
    "options": [
      "Hepatitis B",
      "Hepatitis C",
      "HIV"
    ],
    "hasOtherOption":true,
    "otherOptionTitle":"Other (please provide specific details): "
  },
  {
    "id": "question89",
    "title": "Is the Blood Borne Infection well-controlled?",
    "resultTitle": {
      "True": "Blood Borne Infection well controlled",
      "False": "Blood Borne Infection <b>NOT well controlled</b>"
    },
    "boldDetails": true,
    "detailsOn": "False",
    "type": "BOOLEAN_DETAILS"
  },


  {
    "type": "PAGE_BREAK",
    "id": "page52"
  },
  {
    "type":"SECTION_HEADER",
    "id": "sectionRenal",
    "title":"Renal"
  },
  {
    "id": "question90",
    "title": "Does the patient have Renal Disease/Dysfunction?",
    "resultTitle": "<b>Renal Disease/Dysfunction</b>",
    "hideResult": true,
    "type": "BOOLEAN",
    "onFalse": {"pageNavigationType": "GO_TO_PAGE", "goToPage": "page54"}
  },


  {
    "type": "PAGE_BREAK",
    "id": "page53"
  },
  {
    "id": "question91",
    "title": "Renal Disease/Dysfunction details:",
    "type": "FIELDS",
    "required": false,
    "customRender": "<b>Renal Disease/Dysfunction</b> - Baseline Creatinine: {baselineCreatinine}, eGFR: {egfr}. <div/><div class='ResultIndent'></div>{renalDetails}",
    "questions":[
      {
        "key": "baselineCreatinine",
        "title": "Baseline Creatinine: "
      },
      {
        "key": "egfr",
        "title": "Estimated Glomerular Filtration Rate(eGFR) if available: "
      },
      {
        "key": "renalDetails",
        "title": "Details of renal dysfunction: "
      }
    ]
  },
  {
    "id": "question92",
    "title": "Is the patient on Dialysis:",
    "resultTitle": "<div class='ResultIndent'></div><b>Dialysis</b>",
    "type": "BOOLEAN_DETAILS",
    "detailsTitle": "Dialysis days/details:"
  },


  {
    "type": "PAGE_BREAK",
    "id": "page54"
  },
  {
    "type":"SECTION_HEADER",
    "id": "sectionHepaticDisease",
    "title":"Hepatic Disease/Dysfunction"
  },
  {
    "id": "question93",
    "title": "Does the patient have Hepatic Disease/Dysfunction?",
    "resultTitle": "<b>Hepatic Disease/Dysfunction</b>",
    "boldDetails": true,
    "type": "BOOLEAN_DETAILS"
  },
  {
    "id": "question94",
    "title": "Does the patient have Hepatic Failure?",
    "resultTitle": "<b>Hepatic Failure</b>",
    "boldDetails": true,
    "type": "BOOLEAN_DETAILS",
    "detailsTitle": "Provide details:"
  },


  {
    "type": "PAGE_BREAK",
    "id": "page56"
  },
  {
    "type":"SECTION_HEADER",
    "id": "sectionGastro",
    "hideResult": true,
    "title":"Gastrointestinal Disease/Disorders"
  },
  {
    "id": "question95",
    "title": "Does the patient have Gastrointestinal Disease/Disorder?",
    "resultTitle": "<b>Gastrointestinal Disease/Disorder</b>",
    "boldDetails": true,
    "type": "BOOLEAN_DETAILS"
  },
  {
    "id": "question96",
    "title": "Does the patient have Gastroesophageal Reflux Disease(GERD)?",
    "hideResult": true,
    "type": "BOOLEAN",
    "onFalse": {"pageNavigationType": "GO_TO_PAGE", "goToPage": "page58"}
  },
  
  
  {
    "type": "PAGE_BREAK",
    "id": "page57"
  },
  {
    "id": "question97",
    "title": "Is the Gastroesophageal Reflux Disease (GERD) Management <u>Optimized</u>?",
    "resultTitle": {
      "True": "Gastroesophageal Reflux Disease (<b>GERD</b>) Management Optimized",
      "False": "Gastroesophageal Reflux Disease (<b>GERD</b>) Management <b>NOT Optimized</b>"
    },
    "boldDetails": true,
    "detailsOn": "False",
    "type": "BOOLEAN_DETAILS"
  },
  
  
  {
    "type": "PAGE_BREAK",
    "id": "page58"
  },
  {
    "type":"SECTION_HEADER",
    "id":"sectionRheumatologicDisorders",
    "title":"Rheumatologic Disorders"
  },
  {
    "id": "question98",
    "title": "Does the patient have a Rheumatologic Disease/Disorder?",
    "resultTitle": "Rheumatologic Disease/Disorder",
    "boldDetails": true,
    "type": "BOOLEAN_DETAILS"
  },
  {
    "id": "question99",
    "title": "Is the patient on Biologic Medication?",
    "resultTitle": "<b>Biologic</b> Medication",
    "type": "BOOLEAN_DETAILS"
  },


  {
    "type": "PAGE_BREAK",
    "id": "page61"
  },
  {
    "type":"SECTION_HEADER",
    "id":"sectionPsychiatricDiseaseDisorder",
    "title":"Psychiatric Disease/Disorder"
  },
  {
    "id": "question101",
    "title": "Does the patient have a Psychiatric Disease/Disorder?",
    "resultTitle": "<b>Psychiatric Disease/Disorder</b>",
    "boldDetails": true,
    "type": "BOOLEAN_DETAILS",
    "onFalse":{"pageNavigationType":"GO_TO_PAGE","goToPage":"page63"}
  },


  {
    "type": "PAGE_BREAK",
    "id": "page62"
  },
  {
    "id": "question102",
    "title": "Is the Psychiatric Disease/Disorder <u>Optimized</u>?",
    "resultTitle": {
      "True": "Psychiatric Disease/Disorder Optimized",
      "False": "Psychiatric Disease/Disorder <b>NOT Optimized<b>"
    },
    "boldDetails": true,
    "detailsOn": "False",
    "type": "BOOLEAN_DETAILS"
  },


  {
    "type": "PAGE_BREAK",
    "id": "page63"
  },
  {
    "type":"SECTION_HEADER",
    "id":"sectionCancer",
    "title":"Cancer"
  },
  {
    "id": "question103",
    "title": "Does the patient have a history of Major Cancer?",
    "resultTitle": "<b>History of Major Cancer</b>",
    "type": "BOOLEAN_DETAILS",
    "boldDetails": true,
    "onFalse":{"pageNavigationType":"GO_TO_PAGE","goToPage":"page66"}
  },


  {
    "type": "PAGE_BREAK",
    "id": "page64"
  },
  {
    "id": "question104",
    "title": "Does the patient have a history of Major Cancer in Situ <u>within the Last 6 Months</u>?",
    "hideResult": true,
    "resultTitle": "Major Cancer in Situ within the Last 6 Months",
    "type": "BOOLEAN",
    "onFalse":{"pageNavigationType":"GO_TO_PAGE","goToPage":"page66"}
  },


  {
    "type":"PAGE_BREAK",
    "id":"page65"
  },
  {
    "id": "questionChemotherapy",
    "title": "Has the patient received Chemotherapy for this cancer?",
    "resultTitle": "<div class='ResultIndent'></div>Received Chemotherapy",
    "type": "BOOLEAN"
  },
  {
    "id": "question105",
    "type": "FIELDS",
    "required": false,
    "questions": [
      {"key": "general_details", "title":"Generalized Treatment Plan/Prognosis/Other Details (optional):"}
    ]
  },


  {
    "type":"PAGE_BREAK",
    "id":"page66"
  },
  {
    "type":"SECTION_HEADER",
    "id":"sectionAlcoholUsage",
    "title":"Alcohol Usage"
  },
  {
    "id":"question106",
    "title":"Does the patient drink Alcohol?",
    "hideResult": true,
    "resultTitle": "Drinks Alcohol",
    "type":"BOOLEAN",
    "onFalse": {
      "pageNavigationType":"GO_TO_PAGE",
      "goToPage":"page68"
    },
    "falseTitle":"No, denies alcohol use"
  },


  {
    "type":"PAGE_BREAK",
    "id":"page67"
  },
  {
    "id":"question107",
    "hideResult": true,
    "title":"How much Alcohol does the patient <u>consume</u>?",
    "type":"RADIO",
    "options":[
      "<b><u>Female</u></b>: <b>Less than</b> <u>2 Alcoholic drinks per day</u> or <u>10 drinks per week</u>",
      "<b><u>Female</u></b>: <b>More than</b> <u>2 Alcoholic drinks per day</u> or <u>10 drinks per week</u> (Dx of Abuse)",
      "<b><u>Male</u></b>: <b>Less than</b> <u>3 Alcoholic drinks per day</u> or <u>15 drinks per week</u>",
      "<b><u>Male</u></b>: <b>More than</b> <u>3 Alcoholic drinks per day</u> or <u>15 drinks per week</u> (Dx of Abuse)"
    ]
  },
  {
    "id":"question109",
    "title":"Do the Details or Impression of Alcohol Usage indicate <u>concern for abuse</u>?",
    "resultTitle": "<b>Alcohol</b> usage indicates concern for <b>abuse</b>",
    "type":"BOOLEAN",
    "detailsTitle": "Comments (optional):"
  },


  {
    "type":"PAGE_BREAK",
    "hideResult": true,
    "id":"page68"
  },
  {
    "type":"SECTION_HEADER",
    "id":"sectionCannabis",
    "title":"Cannabis"
  },
  {
    "id":"question110",
    "title":"Does the patient use Cannabis?",
    "resultTitle": "Cannabis",
    "type":"BOOLEAN",
    "falseTitle": "No, denies cannabis use",
    "onFalse": {"pageNavigationType":"GO_TO_PAGE","goToPage":"page70"}
  },

  {
    "type":"PAGE_BREAK",
    "id":"page69"
  },
  {
    "id":"question111",
    "title":"Details of Cannabis Use:",
    "resultTitle": "<b>Cannabis</b>",
    "type":"CHECKBOX",
    "boldDetails": true,
    "boldOptions": true,
    "options":["Smoking Cannabis","Consuming Cannabis"],
    "detailsTitle": "Provide Details: Amount of cannabis/week:"
  },


  {
    "type":"PAGE_BREAK",
    "id":"page70"
  },
  {
    "type":"SECTION_HEADER",
    "id":"sectionRecreationalDrugUsage",
    "hideResult": true,
    "title":"Recreational Drug Usage"
  },
  {
    "id":"question112",
    "title":"Does the patient use Recreational Drugs?",
    "resultTitle": "Recreational Drugs",
    "type":"BOOLEAN_DETAILS",
    "falseTitle": "No, denies use of recreational drugs",
    "boldDetails": true,
    "detailsTitle": "Provide specific details: "
  },

  {
    "type": "PAGE_BREAK",
    "id": "pageChronicPain",
  },
  {
    "type": "SECTION_HEADER",
    "id": "sectionChronicPain",
    "title": "Chronic Pain"
  },
  {
    "id": "questionChronicPain",
    "title": "Does the patient have a known Complex Chronic Pain Disorder?",
    "resultTitle": "<b>Chronic Pain Disorder</b>",
    "type": "BOOLEAN_DETAILS",
  },


  {
    "type":"PAGE_BREAK",
    "id":"page71"
  },
  {
    "type": "SECTION_HEADER",
    "id": "sectionLanguage",
    "title": "Language"
  },
  {
    "id":"question114",
    "hideResult": true,
    "title":"Does the patient have English as a Second Language?",
    "resultTitle": "English as a Second Language",
    "type":"BOOLEAN",
    "onFalse":{"pageNavigationType":"GO_TO_PAGE","goToPage":"page73"}
  },


  {
    "type":"PAGE_BREAK",
    "id":"page72"
  },
  {
    "id":"question115",
    "title":"Does the patient require a Translator?",
    "resultTitle": "English as a Second Language - <b>Requires translator<b>",
    "type":"BOOLEAN_DETAILS",
    "detailsTitle": "Details/language spoken:"
  },


  {
    "type":"PAGE_BREAK",
    "id":"page73"
  },
  {
    "type":"SECTION_HEADER",
    "id": "sectionMentalCapacity",
    "hideResult": true,
    "title":"Mental Capacity"
  },
  {
    "id": "questionMentalDisability",
    "type": "BOOLEAN",
    "resultTitle": "<b>Intellectual Disability/Unable to Sufficiently Answer Medical Questions</b>",
    "title": "Does the patient have an Intellectual Disability with <u>inability to answer medical questions sufficiently</u>?"
  },

  {
    "type": "PAGE_BREAK",
    "id": "pagePregnant",
  },
  {
    "type": "SECTION_HEADER",
    "id": "sectionPregnant",
    "title": "Pregnancy"
  },
  {
    "id":"question116",
    "title":"Is the patient Currently Pregnant?",
    "resultTitle": "<b>Currently Pregnant</b>",
    "hideResult": true,
    "type":"BOOLEAN",
    "onFalse":{"pageNavigationType":"GO_TO_PAGE","goToPage":"pageMedicationScreen"}
  },


  {
    "type":"PAGE_BREAK",
    "id":"page74"
  },
  {
    "id":"question117",
    "title":"Current Pregnancy Details",
    "type":"FIELDS",
    "customRender": "<b>Currently Pregnant</b> - Due Date: {dueDate} - <b>{gestationalAgeSurgeryWeeks} weeks GA on day of surgery</b> - {ObstetricalPhysician}",
    "resultPrependTitles": true,
    "questions":[
      {
        "key": "dueDate",
        "title": "Due Date (yyyy/mm/dd)",
        "pattern": "[0-9]{4}/(0[1-9]|1[0-2])/(0[1-9]|[12][0-9]|3[01])",
        "placeholder": "YYYY/MM/DD",
        "type": "date"
      },
      {
        "key": "gestationalAgeSurgeryWeeks",
        "title": "# Weeks Gestational Age on day of surgery",
        "inputMode": "numeric",
        "type": "number"
      },
      {
        "key": "ObstetricalPhysician",
        "title": "Obstetrical Physician"
      }
    ]
  },

  {
    "type": "PAGE_BREAK",
    "id": "pageMedicationScreen"
  },
  {
    "type":"SECTION_HEADER",
    "id": "sectionMedicationScreen",
    "hideResult": true,
    "title":"Medication Screen"
  },
  {
    "id":"question119",
    "title":"Is the patient on any of the following specific Medication Types?",
    "type":"CHECKBOX",
    "hideResult": true,
    "required": false,
    "options":[
      {"title": "GLP-1 agonists (xxx-tide)", "value":"GLP-1"},
      {"title": "SGLT-2 inhibitors (xxx-gliflozin)", "value": "SGLT-2"},
      "Digoxin",
      "Lithium",
      "Diuretics",
      "ACE-inhibitors",
      {"title":"Angiotensin II Receptor Blockers (ARB's)", "value": "ARB"},
      "NSAIDs",
      {"title":"ASA 325mg or higher","value":"ASA high"}
    ]
  },


  {
    "type":"PAGE_BREAK",
    "id":"pagePhysicalExam"
  },
  {
    "type":"SECTION_HEADER",
    "id":"sectionFocusedPhysicalExamination",
    "title":"Focused Physical Examination"
  },
  {
    "id":"question120",
    "title":"<b><u>Measurements/Vitals</u></b>",
    "type":"FIELDS",
    "resultTitle": "<h2>Physical Exam Notables</h2>",
    "customRender": "<h2><u>Physical Exam Notables</u></h2><p>{weight} kg, {height} cm, BMI: {bmi}, HR: {heartrate}, BP: {bloodpressure}, RR: {respirationrate}, SpO2: {spo2}</p>",
    "questions":[
      {
        "key": "weight",
        "type": "number",
        "inputMode": "decimal",
        "title": "Weight: (kg)",
      },{
        "key": "height",
        "type": "number",
        "inputMode": "decimal",
        "title": "Height: (cm)",
      },{
        "key": "bmi",
        "type": "number",
        "inputMode": "decimal",
        "title": "BMI: (kg/m2)",
      },{
        "key": "neck_circumference",
        "type": "number",
        "inputMode": "decimal",
        "title": "Neck Circumference: (cm)",
      },{
        "key": "heartrate",
        "title": "HR: (beats/min)",
      },{
        "key": "bloodpressure",
        "title": "BP: (SBP/DBP mmHg)",
      },{
        "key": "respirationrate",
        "title": "RR: (breaths/min)",
      },{
        "key": "spo2",
        "title": "SpO2: (%)",
      },{
        "key": "temperature",
        "title": "Temp: (Celsius)",
      }
    ]
  },
  {
    "id": "questionBMIExtremes",
    "title": "BMI Description",
    "resultTitle": "<br/>BMI Description",
    "type": "RADIO",
    "options": [
      {title:"Morbidly Obese",resultTitle: "<b>Morbid Obesity</b>"},
      {title: "Normal", hideResult: true},
      {title:"Underweight",resultTitle: "<b>Underweight</b>"}
    ]
  },
  {
    "id": "questionSevereMalourishment",
    "title": "Nutrition - Is this patient at risk of Severe Malnourishment?",
    "type": "BOOLEAN",
    "falseTitle": "No - unlikely",
    "trueTitle": "Yes - I am concerned",
    "resultTitle": "Nutrition - <b>Severe Malnourishment risk</b>"
  },
  {
    "id":"question121",
    "title":"<b><u>General Appearance</u></b>",
    "resultTitle": "General Appearance Other",
    "boldDetails": true,
    "type":"BOOLEAN_DETAILS",
    "falseTitle": "Normal",
    "trueTitle":"Other",
    "detailsTitle":"Other (provide details):"
  },
  {
    "id":"question122",
    "title":"Does patient have a <b><u>KNOWN</u> Airway Alert</b> and/or do you have <b>Airway Concerns</b>?",
    "resultTitle": "<b>Known Airway Alert/Concerns</b>",
    "boldDetails": true,
    "type":"BOOLEAN_DETAILS",
    "detailsRequired": false,
    "detailsTitle":"Other (provide details):"
  },
  {
    "id":"question124",
    "title":"<b><u>Cardiac Exam</u></b>: Cardiac Murmur?",
    "type":"RADIO",
    "resultTitle": "Cardiac Exam",
    "boldDetails": true,
    "options": [
      {"title": "No",hideResult: true},
      {"title":"Yes (pre-existing)", "resultTitle": "(pre-existing)"},
      {"title":"Yes (new)", "resultTitle": "(new)"}
    ],
    "detailsTitle":"Other abnormal cardiac findings (provide details):"
  },
  {
    "id":"questionRespiratoryExam",
    "hideResult": true,
    "title":"Respiratory Exam:",
    "type":"SECTION_HEADER"
  },
  {
    "type": "BOOLEAN_DETAILS",
    "id": "questionAirEntry",
    "title": "Air Entry",
    "falseTitle": "Normal",
    "trueTitle": "Abnormal",
    "boldDetails": true,
    "detailsTitle": "Describe"
  },
  {
    "type": "BOOLEAN_DETAILS",
    "id": "questionAdventitiousSounds",
    "title": "Adventitious Sounds (crackles, rhonchi, wheeze) ",
    "falseTitle": "Absent",
    "trueTitle": "Present",
    "boldDetails": true,
    "detailsTitle": "Describe"
  },
  {
    "id":"questionRespiratoryOther",
    "type":"BOOLEAN_DETAILS",
    "title": "Other Respiratory Findings",
    "resultTitle": "Other Respiratory Findings",
    "boldDetails": true,
    "detailsTitle": "Other (provide details):"
  },
  {
    "id":"question127",
    "title":"<b><u>Extremities:</u></b> (Circulation - Pulses/Edema/Cyanosis/Clubbing):",
    "resultTitle": "Extremities exam",
    "boldDetails": true,
    "type":"BOOLEAN_DETAILS",
    "falseTitle": "No Abnormalities",
    "detailsTitle":"Other (provide details):"
  },
  {
    "id":"question128",
    "title":"<b><u>Other Relevant Exam Findings:</u></b>",
    "resultTitle": "Other Relevant exam findings",
    "boldDetails": true,
    "type":"BOOLEAN_DETAILS",
    "falseTitle":"None",
    "detailsTitle":"Other (provide details):"
  },

  {
    "type": "PAGE_BREAK",
    "id": "pageAssessorComments",
    "hideResult": true,
  },
  {
    "type":"SECTION_HEADER",
    "id": "sectionAssessorComments",
    "hideResult": true,
    "title":"Comments"
  },
  {
    "type": "TEXTAREA",
    "id": "questionAssessorComments",
    "title": "General Comments from Assessor: (optional)",
    "required": false
  },


  {
    "type":"PAGE_BREAK",
    "id":"page78"
  },
  {
    "type":"SECTION_HEADER",
    "id":"sectionPreopConsultations",
    "title":"Preoperative Consultations"
  },
  {
    "id": "paragraphSubspecialtyFollowUp",
    "type":"PARAGRAPH",
    "body":"<b><u>Subspecialty Review/Follow-up</u></b> (Hematology, Cardiology, Respirology, Neurology etc.) may be required in certain situations prior to surgery. &nbsp;This may be for more detailed preparation and optimization <b>or</b> assessment of risk <b>or</b> to clarify health information - <b>at your discretion</b>. &nbsp;Generally if a review already happens regularly, it is advantageous if able to <u>(re)schedule a date PRIOR TO SURGERY</u> if possible."
  },
  {
    "id":"question131a",
    "hideResult": true,
    "title": "Is a subspecialty follow-up required?",
    "resultTitle": "Subspecialty follow-up required",
    "type": "BOOLEAN",
    "onFalse": {"pageNavigationType":"GO_TO_PAGE","goToPage":"page77"}
  },

  {
    "type": "PAGE_BREAK",
    "hideResult": true,
    "id": "pageFollowUp"
  },
  {
    "id":"questions131b",
    "title":"Kindly arrange the follow-up if applicable, or have patient make arrangements by contacting the subspecialist's office. &nbsp;<br/><b><u>Indicate reason for request</u></b> for the subspecialty follow-up",
    "type":"FIELDS",
    "resultPrependTitles": true,
    "resultSeparator": ": ",
    "required": false,
    "questions":[
      {"key": "allergy_immunology", "title": "Allergy and Immunology"},
      {"key":"cardiology", "title":"Cardiology"},
      {"key":"chronic_pain", "title":"Chronic Pain"},
      {"key":"dermatology", "title":"Dermatology"},
      {"key":"endocrinology", "title":"Endocrinology"},
      {"key":"gastroenterology", "title":"Gastroenterology"},
      {"key":"geriatrics", "title":"Geriatrics"},
      {"key":"hematology", "title":"Hematology"},
      {"key":"infectious_disease", "title":"Infectious Disease"},
      {"key":"nephrology", "title":"Nephrology"},
      {"key":"neurology", "title":"Neurology"},
      {"key":"oncology", "title":"Oncology"},
      {"key":"psychiatry", "title":"Psychiatry"},
      {"key":"respirology", "title":"Respirology"},
      {"key":"rheumatology", "title":"Rheumatology"},
      {"key":"sleep_medicine", "title":"Sleep Medicine"},
      {"key":"surgical_subspecialty", "title":"Surgical Subspecialty (indicate Surgical service)", "resultTitle": "Surgical Subspecialty"},
      {"key":"other_subspecialty", "title":"Other Subspecialty (specify service)", "resultTitle": "Other Subspecialty"},
    ]
  },
  
  {
    "type":"PAGE_BREAK",
    "id":"page77"
  },
  {
    "type":"SECTION_HEADER",
    "id":"sectionPreopPrepOptimization",
    "title":"Preoperative Preparation/Optimization"
  },
  {
    "type": "PARAGRAPH",
    "id": "paragraphPreopPrep",
    "title": "Preoperative Chronic Disease Optimization",
    "body": "The Following Preoperative Assessment Clinic Consultations will be arranged:"
  },

  {
    "type": "PARAGRAPH",
    "subtype": "list",
    "id": "paragraphPrepNone",
    "body": "<b>None</b>"
  },
  {
    "type": "PARAGRAPH",
    "subtype": "list",
    "id": "paragraphAnesthesiology",
    "body": "<b>Anesthesiology</b>"
  },
  {
    "type": "PARAGRAPH",
    "subtype": "list",
    "id": "paragraphPrepGeneral",
    "body": "<b>General Internal Medicine</b>"
  },
  {
    "type": "PARAGRAPH",
    "id": "paragraphConsultsAutoGenerated",
    "subtype": "flat",
    "body": "<i>**Note: Anesthesiology and/or GIM Preoperative Assessment Clinic (PAC) Consultations are automatically generated based on the answers to the questions in the assessment. &nbsp;The logic follows a department-approved custom consultation guide.<i>"
  },

  {
    "id":"question130",
    "type":"PARAGRAPH",
    "title":"The following medical conditions were indicated to be unoptimized. &nbsp;Please address and indicate interventions:",
    "body": "",
  },
  {
    "id":"question130-none",
    "type": "PARAGRAPH",
    "hideResult": true,
    "body":"<b>None</b>"
  },
  {
    "id":"question130-gerd",
    "title":"",
    "hideResult": true,
    "type":"CHECKBOX",
    "options":["Ensure GERD therapy effective"],
    "detailsTitle": "Intervention:"
  },
  {
    "id":"question130-inhalers",
    "title":"",
    "hideResult": true,
    "type":"CHECKBOX",
    "options":["Optimize inhalers for asthma/COPD"],
    "detailsTitle": "Intervention:"
  },
  {
    "id":"question130-glucose",
    "title":"",
    "hideResult": true,
    "type":"CHECKBOX",
    "options":["Improve diabetic glucose control"],
    "detailsTitle": "Intervention:"
  },
  {
    "id":"question130-smoking",
    "title":"",
    "hideResult": true,
    "type":"CHECKBOX",
    "options":["Encourage smoking cessation"],
    "detailsTitle": "Intervention:"
  },
  {
    "id":"question130-iron",
    "title":"",
    "hideResult": true,
    "type":"CHECKBOX",
    "options":["Consider iron supplementation/infusions for anemia"],
    "detailsTitle": "Intervention:"
  },
  {
    "id":"question130-htn",
    "title":"",
    "hideResult": true,
    "type":"CHECKBOX",
    "options":["Adjust medications for uncontrolled HTN"],
    "detailsTitle": "Intervention:"
  },
  {
    "id":"question130-murmur",
    "title":"",
    "hideResult": true,
    "type":"CHECKBOX",
    "options":["Initiate investigations for newly discovered murmur"],
    "detailsTitle": "Intervention:"
  },
  {
    "id":"question130-other",
    "title":"",
    "hideResult": true,
    "type":"CHECKBOX",
    "options":[],
    "hasOtherOption":true,
    "otherOptionTitle":"Indicate any (other) interventions initiated today:",
    "detailsTitle": "Intervention:"
  },

  {
    "id": "sectionInvestigationDocuments",
    "type": "SECTION_HEADER",
    "title": "Investigation Documents"
  },
  {
    "id": "paragraphPertinentInvestigations",
    "type": "PARAGRAPH",
    "title": "Pertinent Investigations - Please attach a copy of the following cardiac test results (most recent only):",
    "body": "{question61}"
  },
  {
    "id": "questionCardiacRecent",
    "type": "CHECKBOX",
    "hideResult": true,
    "options": ["Most recent results attached"],
  },



  {
    "type":"PAGE_BREAK",
    "hideResult": true,
    "id":"page80"
  },
  {
    "type":"SECTION_HEADER",
    "id":"sectionPreopMedManagement",
    "title":"Medication Management for Day of Surgery"
  },
  {
    "id": "paragraphConsultsGenerated",
    "type":"PARAGRAPH",
    "body":"Preoperative medication instructions will be provided at the Preoperative Assessment Clinic visit"
  },
  {
    "id": "paragraphConsultsNotGenerated",
    "type":"PARAGRAPH",
    "title": "Since this patient is <u>NOT</u> being referred to the <u>PREOPERATIVE ASSESSMENT CLINIC</u>, use the following guide to provide patient medication instructions:",
    "body":"<div><div class='columns'><div><h4><b>MUST HOLD</b> morning of surgery:</h4><li>Oral Hypoglycemics</li><li>Diuretics</li><li>ACE-inhibitors</li><li>Angiotensin II Receptor Blockers (ARBs)</li><li>NSAIDS</li><li>Anti-gout medications</li><li>Erectile Dysfunction medications</li><li>CNS stimulants</li><li>Vitamins/Herbals - hold 3 weeks preop</li><li><a href='#glp1'>GLP-1 Agonists - Hold for 3 weeks preop</a></li><li>SGLT-2 Inhibitors - Hold for 3 days preop</li></div><div><h4><b>MUST TAKE</b> morning of surgery (with a sip of water):</h4><li>Beta blockers</li><li>Inhalers</li><li>PPIs and H2 blockers</li><li>Parkinson’s medications</li><li>Anti-seizure medications</li><li>Psychiatric medications</li><li>Chronic pain medications </li><li>Methadone</li></div></div><div><p>Most other medications have reduced preoperative significance and should be taken as per usual times of day. &nbsp;</p><p><b>***Anticoagulants*** to be advised by surgeon or perioperative physician. &nbsp</b></p><p><b>If patient is on prophylactic ASA 81mg, they should DISCONTINUE taking it 7 days before surgery unless notified by surgeon or perioperative physician that it is <u>specifically</u> to be continued throughout the perioperative period. &nbsp;Decisions are made on a case-by-case basis. &nbsp;</b></p><p><b>Please provide patient with this completed instruction sheet for which specific medications to HOLD morning of surgery, and which to TAKE.</b> &nbsp;(This instruction sheet will print off at end of this assessment)</p></div></div>"
  },
  {
    "type": "TEXTAREA",
    "id": "questionMustHold",
    "title": "<b>Please HOLD</b> (fill out and indicate duration to hold if longer than just one morning dose)",
    "fillText": "None",
    "fillTitle": "None"
  },
  {
    "type": "FIELDS",
    "id": "questionMustHoldSpecificGLP1",
    "title": "Must hold GLP-1 Agonist for 3 weeks",
    "questions": [
      {
        "key": "glp1",
        "type": "string",
        "title": "Drug Name:"
      }
    ]
  },
  {
    "type": "FIELDS",
    "id": "questionMustHoldSpecificSGLT2",
    "title": "Must hold SGLT-2 Agonist for 3 days",
    "questions": [
      {
        "key": "sglt2",
        "type": "string",
        "title": "Drug Name:"
      }
    ]
  },
  {
    "type": "TEXTAREA",
    "id": "questionMustTake",
    "title": "<b>Please TAKE</b> (fill out)",
    "fillText": "None",
    "fillTitle": "None"
  },


  {
    "type": "PAGE_BREAK",
    "id": "pagePreopLabTests"
  },
  {
    "type": "SECTION_HEADER",
    "id": "sectionPreopLabTests",
    "title": "Preoperative Laboratory Tests/Investigations"
  },
  {
    "type": "PARAGRAPH",
    "id": "paragraphRequiredTestsNoConsult",
    "body": "Based on patient medical history, the <b>following laboratory tests/investigations are required</b> prior to surgery:"
  },
  {
    "type": "PARAGRAPH",
    "id": "paragraphRequiredTestsConsult",
    "body": "Based on the patient's medical history, the <b>following laboratory tests/investigations are requested</b> prior to the PAC visit:"
  },
  {
    "type": "PARAGRAPH",
    "subtype": "list",
    "id": "pTestNoneLocalAnesthetic",
    "body": "<b>None (procedural under local/topical/eye block)</b>"
  },
  {
    "type": "PARAGRAPH",
    "subtype": "list",
    "id": "pTestNone",
    "body": "<b>None</b>"
  },
  {
    "type": "PARAGRAPH",
    "subtype": "list",
    "id": "pTestHCG",
    "body": "HCG (within 3 months)"
  },
  {
    "type": "PARAGRAPH",
    "subtype": "list",
    "id": "pTestCBC",
    "body": "CBC (within 3 months)"
  },
  {
    "type": "PARAGRAPH",
    "subtype": "list",
    "id": "pTestGroupScreen",
    "body": "Group and Screen (within 6 months)"
  },
  {
    "type": "PARAGRAPH",
    "subtype": "list",
    "id": "pTestECG",
    "body": "ECG (within 3 months)"
  },
  {
    "type": "PARAGRAPH",
    "subtype": "list",
    "id": "pTestRenal",
    "body": "Renal Panel - Electrolytes - 6, Urea, Creatinine (within 3 months)"
  },
  {
    "type": "PARAGRAPH",
    "subtype": "list",
    "id": "pTestPTT",
    "body": "PTT, INR (within 3 months)"
  },
  {
    "type": "PARAGRAPH",
    "subtype": "list",
    "id": "pTestLiver",
    "body": "Liver Function Tests (within 3 months)"
  },
  {
    "type": "PARAGRAPH",
    "subtype": "list",
    "id": "pTestGlucose",
    "body": "Blood Glucose (within 3 months)"
  },
  {
    "type": "PARAGRAPH",
    "subtype": "list",
    "id": "pTestHbA1C",
    "body": "HbA1C (within 3 months)"
  },
  {
    "type": "PARAGRAPH",
    "subtype": "list",
    "id": "pTestCXR",
    "body": "CXR (within 6 months)"
  },
  {
    "type": "PARAGRAPH",
    "subtype": "list",
    "id": "pTestTSH",
    "body": "TSH (within 6 months)"
  },
  {
    "type": "PARAGRAPH",
    "id": "paragraphEnsure",
    "title": "PLEASE ENSURE PATIENT RECEIVES LAB TEST/INVESTIGATION REQUISITION FOR THE RECOMMENDED TESTS.",
    "body": "For patients having surgical procedures under local/topical anesthesia, and no new/worsened symptoms, evidence suggests there is no need for testing. &nbsp;For patients with complex or uncommon surgical or medical conditions, tests beyond these guidelines may be appropriate based on the clinical judgement of the health-care provider."
  },


  {
    "type":"PAGE_BREAK",
    "hideResult": true,
    "id":"page81"
  },
  {
    "type":"SECTION_HEADER",
    "id":"sectionMedicalID",
    "title":"Medical ID (Complete Medical Record)"
  },
  {
    "id":"question135",
    "title":"Please attach: <b><u>updated office version of complete medical history</u></b> for perioperative reference, to be placed in patient's hospital chart.",
    "type":"RADIO",
    "options":["I verify to have attached an <b><u>updated office version of complete medical history</u></b> for perioperative reference, to be placed in patient's hospital chart. &nbsp;<br/>Tick when complete."]
  },
]

const pages: number[]= [];
// Set defaults for fields
DOAForm.forEach((item, index) => {
  item.required = item.required ?? true;
  if (item.type=== "PAGE_BREAK") {
    pages.push(index);
  }

  if (item.type === "BOOLEAN_DETAILS") {
    item.detailsTitle = item.detailsTitle ?? 'Please provide details:';
    item.detailsOn = item.detailsOn ?? "True";
    item.detailsRequired = item.detailsRequired ?? true;
  }
});

export default DOAForm;
