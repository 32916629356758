import React, {ChangeEventHandler, useRef} from 'react';
import {FormItemTypes} from './data/form';
import DatePicker from 'react-date-picker';
import './FormItems.scss';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'

export function FormQuestion(props:{
  item:FormItemTypes,
  defaultValues:{[key:string]: string},
  onChange:ChangeEventHandler<HTMLInputElement>,
  children:React.ReactNode
}) {
  const {item, onChange, defaultValues} = props;
  const detailsID = item.id + '-Details';
  const detailsVisible = (!item.detailsOn || item.detailsOn === defaultValues[item.id]);
  return (
    <div id={item.id} className='FormItem'>
      {item.title && <h4 dangerouslySetInnerHTML={{__html: item.title}} />}
      {props.children}
      {(detailsVisible && item.detailsTitle) && (
        <div className='Details'>
          <label htmlFor={detailsID} dangerouslySetInnerHTML={{__html:item.detailsTitle}} />
          <input
            id={detailsID}
            name={detailsID}
            defaultValue={defaultValues[detailsID]}
            required={item.detailsRequired && detailsVisible && !!item.detailsTitle}
            onChange={onChange}
          />
        </div>
      )}
    </div>
  );
}

export default function FormItem(props:{
  item:FormItemTypes,
  onChange:ChangeEventHandler<HTMLInputElement|HTMLTextAreaElement>,
  values:any,
}) {
  const {item, values, onChange} = props;
  const optionRef = useRef<HTMLInputElement>(null);
  const onOtherInput = () => {
    if (optionRef?.current) {
      optionRef.current.checked = true;
    }
  };
  switch (item.type) {
    case 'BOOLEAN_DETAILS':
      return (
        <FormQuestion item={item} defaultValues={values} onChange={onChange}>
          <div className='FormBooleanDetails'>
            <input id={item.id + '-False'} type='radio' name={item.id} checked={values[item.id] === 'False'} value='False' onChange={onChange} required={item.required} />
            <label htmlFor={item.id + '-False'} dangerouslySetInnerHTML={{ __html: item.falseTitle || 'No'}} />
          </div>
          <div className='FormBooleanDetails'>
            <input id={item.id + '-True'} type='radio' name={item.id} checked={values[item.id] === 'True'} value='True' onChange={onChange} required={item.required} />
            <label htmlFor={item.id + '-True'} dangerouslySetInnerHTML={{ __html: item.trueTitle || 'Yes'}} />
          </div>
        </FormQuestion>
      );
    case 'BOOLEAN':
      return (
        <FormQuestion item={item} defaultValues={values} onChange={onChange}>
          <div className='FormBoolean'>
            <input id={item.id + '-False'} type='radio' name={item.id} checked={values[item.id] === 'False'} value='False' onChange={onChange} required={item.required} />
            <label htmlFor={item.id + '-False'} dangerouslySetInnerHTML={{ __html: item.falseTitle || 'No' }} />
          </div>
          <div className='FormBoolean'>
            <input id={item.id + '-True'} type='radio' name={item.id} checked={values[item.id] === 'True'} value='True' onChange={onChange} required={item.required} />
            <label htmlFor={item.id + '-True'} dangerouslySetInnerHTML={{ __html: item.trueTitle || 'Yes' }} />
          </div>
        </FormQuestion>
      );
    case 'CHECKBOX':
      return (
        <FormQuestion item={item} defaultValues={values} onChange={onChange}>
          <div className='FormCheckbox'>
            {item.options.map((option, index) => {
              if (typeof option === 'string') {
                option = {title: option};
              }
              const id = item.id + '-' + (option.value??index);
              const value = option.value??option.title;
              return (
                <div key={id} className='FormCheckboxOption'>
                  <input id={id} type='checkbox' name={item.id} defaultChecked={values[item.id]?.includes?.(value)} value={value} onChange={onChange} required={item.required && !values[item.id]?.length} />
                  <label htmlFor={id}>{option.title}</label>
                </div>
              );
            })}
            {item.hasOtherOption && (
              <div className='FormCheckboxOption'>
                <input id={item.id + '-OtherOption'} ref={optionRef} type='checkbox' name={item.id} defaultChecked={values[item.id]?.includes?.('Other')} value='Other' onChange={onChange}/>
                <label htmlFor={item.id + '-Other'}>{item.otherOptionTitle || 'Please provide details: '}</label>
                <input id={item.id + '-Other'} name={item.id + '-Other'} defaultValue={values[item.id + '-Other']} onChange={onChange} onInput={onOtherInput}/>
              </div>
            )}
          </div>
        </FormQuestion>
      );
    case 'FIELDS':
      
      return (
        <FormQuestion item={item} defaultValues={values} onChange={onChange}>
          <div className='FormFields'>
            {item.questions.map((question) => {
              const id = item.id + '-' + (question.key||question.title);
              const isPhone = question.inputMode === 'tel';
              const isDate = question.type === 'date' || question.type === 'month';
              return (
                <div key={id} className='FormFieldQuestion'>
                  <label htmlFor={id}>{question.title}</label>
                  {isPhone && (
                    <div className='FormFieldTelephone'>
                    <PhoneInput
                      inputProps={{
                        name: id,
                        required: true,
                      }}
                      country="ca"
                      countryCodeEditable={false}
                      value={values[id]}
                      disableDropdown
                      isValid={(value, country) => {
                        // @ts-ignore
                        const code = country.iso2;
                        if (code === "ca" || code === "us") {
                          return value.length === 11;
                        }
                        return true;
                      }}
                      // @ts-ignore
                      onChange={(phone) => onChange({target: {id: id, name: id, value: phone}})}
                    />
                    </div>
                  )}
                  {isDate && (
                    <div id={id}>
                      {/* @ts-ignore disableCalendar is a viable option for */}
                      <DatePicker clearIcon={null} openCalendarOnFocus={false} name={id} value={values[id] ? new Date(values[id].replaceAll("-","/")) : undefined} onChange={(date:Date) => {
                        let dateString = undefined;
                        if (date) {
                          dateString = `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${date.getDate()}`;
                        }
                        //@ts-ignore
                        onChange({target: {id: id, name: id, value: dateString}});
                      }} yearPlaceholder="yyyy" monthPlaceholder="mm" dayPlaceholder="dd"
                        maxDate={new Date("9999-12-31")}
                        format={question.type === 'month' ? "y/MM" :"y/MM/dd"}
                        required={question?.required ?? item.required} />
                    </div>
                    // <input value={values[id]} onChange={onChange}  />
                  )}
                  {(!isDate && !isPhone) && (
                    <input id={id} name={id} maxLength={question.maxLength} inputMode={question.inputMode} type={question.type} max={question.type === "date" ? "9999-12-31" : undefined} value={values[id]??""} placeholder={question.placeholder} pattern={question.pattern} onChange={onChange} required={question?.required ?? item.required} />
                  )}
                </div>
              );
            })}
          </div>
        </FormQuestion>
      );
    case 'BOOLEAN_GRID':
    case 'GRID':
      return (
        <FormQuestion item={item} defaultValues={values} onChange={onChange}>
          <div className='FormGrid' style={{gridAutoColumns: item.columns.length-1}}>
            <div className='FormGridHeader'>
              <div className='FormGridHeaderItem' />
              {item.columns.map((column, index) => {
                const text = column.title;
                return <div className='FormGridHeaderItem' key={index} dangerouslySetInnerHTML={{__html: text}} />;
              })}
            </div>
            {item.rows.map((row, index) => {
              if (typeof row === 'string') {
                row = {key: row, title: row};
              }
              const rowId = item.id + '-' + row.key;
              const rowClass = index % 2 ? 'FormGridRowOdd' : 'FormGridRowEven';
              const options = item.columns.map((column, index) => {
                const inputID = rowId + '-' + index;
                const text = column.key;
                return <input key={inputID} id={inputID} type='radio' name={rowId} value={text} checked={values[rowId] === column.key} onChange={onChange} required={item.required} className={"__"+text}/>
              });
              const yesSelected = values[rowId] === "Yes";
              const hideRowDetails = item.type === 'BOOLEAN_GRID' && !yesSelected;
              // const detailsRequired = item.type === 'BOOLEAN_GRID' && yesSelected;
              return (
                <React.Fragment key={rowId}>
                  <div className={`FormGridRow ${rowClass}`}>
                    <label dangerouslySetInnerHTML={{__html: row.title}} />
                    {options}
                  </div>
                  {item.rowDetailsTitle && (
                    <div className={`FormGridRowDetails ${rowClass}`}>
                      <div style={{visibility: hideRowDetails ? 'hidden' : undefined}}>
                        <label htmlFor={rowId+'-Details'}>{item.rowDetailsTitle}</label>
                        <input type='text' id={rowId + '-Details'} name={rowId + '-Details'} defaultValue={values[rowId + '-Details']} onChange={onChange}/>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          </div>
            {item.selectAll != null && <div className='FormGridHeader'>
              <div className="FormGridHeaderItem" />
              <div className="FormGridHeaderItem">
              <button type="button" className="button" onClick={() => {
                item.rows.forEach((row) => {
                  if (typeof row === 'string') {
                    row = {key: row, title: row};
                  }
                  const rowId = item.id + '-' + row.key;
                  const radio = document.getElementById(rowId + "-" + item.selectAll)
                  setTimeout(() => {
                    radio?.click();
                  }, 1)
                })
              }}>{item.columns[item.selectAll].title} to all</button>
              </div>
              <div className="FormGridHeaderItem" />
            </div>}
        </FormQuestion>
      );
    case 'PAGE_BREAK':
      return null;
    case 'PARAGRAPH':
      const iter = item.body.matchAll(/{.*?}/g);
      let cur = iter.next();
      let body = item.body;
      while (!cur.done) {
        const match = cur.value[0];
        const fieldid = match.slice(1, -1);
        let value = values[fieldid];
        let valueRender = value ?? "N/A";
        const other = values[fieldid + "-Other"];
        
        if (Array.isArray(value)||other) {
          value = value ?? [];
          // TODO: Custom Render for question61 and paragraphPertinentInvestigations
          if (item.id === "paragraphPertinentInvestigations" && fieldid === "question61") {
            let yearlyEcho = false;
            let singleEcho = false;
            value = value.filter((x:string) => {
              if (x === "Yearly Echocardiograms") {
                yearlyEcho = true;
                return false;
              }
              if (x === "Echocardiogram") singleEcho = true;
              return true;
            });
            if (yearlyEcho && !singleEcho) {
              value.push("Echocardiogram")
            }
          }
          if (other) {
            value = value.filter((x:string) => x !== "Other");
            value = [...value, "Other: " + other];
          }
          valueRender = value.length ? value.join(", ") : "N/A";
        }
        body = body.replace(match, valueRender);
        cur = iter.next();
      }
      if (item?.subtype === "list") {
        return (
          <div id={item.id} className='FormItem' style={{background:"none", border: "none", paddingTop: 0, paddingBottom: 0}}>
            <li className='FormParagraph' dangerouslySetInnerHTML={{__html:body}} />
          </div>
        );
      }
      if (item?.subtype === 'flat') {
        return (
          <div id={item.id} className='FormItem' style={{background:"none", border: "none", paddingTop: 0, paddingBottom: 0}}>
            <p className='FormParagraph' dangerouslySetInnerHTML={{__html:body}} />
          </div>
        )
      }
      return (
        <FormQuestion item={item} defaultValues={values} onChange={onChange}>
          <p className='FormParagraph' dangerouslySetInnerHTML={{__html:body}} />
        </FormQuestion>
      );
    case 'RADIO':
      return (
        <FormQuestion item={item} defaultValues={values} onChange={onChange}>
          <div className='FormRadio'>
            {item.options.map((option, index) => {
              const id = item.id + '-' + index;
              if (typeof option === 'string') {
                option = {title: option, value: option};
              } else if (!option.value) {
                option.value = option.title;
              }

              return (
                <div key={id} className='FormRadioOption'>
                  <input id={id} type='radio' name={item.id} checked={values[item.id] === option.value} value={option.value} onChange={onChange} required={item.required} />
                  <label htmlFor={id} dangerouslySetInnerHTML={{__html: option.title}} />
                </div>
              );
            })}
            {item.hasOtherOption && (
              <div className='FormRadioOption'>
                <input ref={optionRef} id={item.id + '-OtherOption'} type='radio' name={item.id} defaultChecked={values[item.id] === 'Other'} value={'Other'} onChange={onChange} required={item.required} />
                <label htmlFor={item.id + '-Other'}>{item.otherOptionTitle || 'Please provide details: '}</label>
                <input id={item.id + '-Other'} name={item.id + '-Other'} defaultValue={values[item.id + '-Other']} onChange={onChange} onInput={onOtherInput} required={item.required && values[item.id] === "Other"} />
              </div>
            )}
          </div>
        </FormQuestion>
      );
    case 'SECTION_HEADER':
      if (!item.title) {
        return null;
      }
      if (item.title === "&nbsp;") {
        return <h2 id={item.id}>&nbsp;</h2>
      }
      return <h2 id={item.id}>{item.title}</h2>;
    case 'TEXTAREA':
      return (
        <FormQuestion item={item} defaultValues={values} onChange={onChange}>
          <div className='FormTextarea'>
            <textarea id={item.id} name={item.id} value={values[item.id]} onChange={onChange} required={item.required} />
          </div>
          
          {item.fillTitle && (
            <button type="button" className="button"
              onClick={() => 
                // @ts-ignore
                onChange({target: {id: item.id, name: item.id,value: item.fillText || "N/A"}})
              }
            >
              {item.fillTitle}
            </button>)}
        </FormQuestion>
      );
    case 'VALUES':
      const value = values[item.field];
      let valueRender = value;
      if (Array.isArray(value)) {
        const other = values[item.field + "-Other"];
        if (other) {
          value.push(other)
        }
        valueRender = value.map((v) => v);
      }
      
      return (
        <FormQuestion item={item} defaultValues={values} onChange={onChange}>
          <div>
            {valueRender}
          </div>
        </FormQuestion>
      );
    default:
      return null;
  }
}
