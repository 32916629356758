import React from 'react';
import {FormValues, FormValue} from './data/form';
import Keystore from './KeyStore';
import './Helpers.scss'
import './Results.scss'
import ResultsArrayV1 from "./ResultsCustomV1";
import Modal from 'react-modal';
import Complete from './Complete';
import Calculations from './Calculation';
import doacalc from './data/DOACalc';
import Header from './components/Header';
import Restart from './modals/Restart';

type FormItemsState = {
  page:number,
  pages:number[],
  pageHistory: number[],
  /** Store form results within pages,
   * if a page is no longer part of the history ignore
   */
  fieldPage: {[fieldid:string]:number},
  formValues: FormValues,
  modalOpen?: string,
  printed: boolean,
}

export default class Results extends React.Component<{onEdit?:(() => void)}, FormItemsState> {
  calc: Calculations;
  getValue: (id: string) => FormValue;
  showField: (id: string) => boolean;
  constructor(props:any) {
    super(props);
    this.state = {
      page: -1,
      pageHistory: [],
      pages: [],
      fieldPage: {},
      formValues: {},
      modalOpen: undefined,
      printed: false,
    };
    const storedState = Keystore.loadState();
    if (storedState) {
      try {
        let filteredFormValues:FormValues = {};
        const keys = Object.keys(storedState.formValues);
        const validPages = new Set(storedState.pageHistory);
        keys.forEach((key) => {
          if (validPages.has(storedState.fieldPage[key])) {
            filteredFormValues[key] = storedState.formValues[key]
          };
        });
        this.state = {
          ...this.state,
          ...storedState,
          filteredFormValues,
        }
      } catch (err) {
        console.warn(err);
      }
    }
    this.calc = new Calculations(doacalc);
    this.getValue = (id:string) => {
      const item_page = this.state.fieldPage[id];
      return this.state.pageHistory.includes(item_page) || this.state.page === item_page
        ? this.state.formValues[id]
        : undefined;
    }
    this.showField = (id:string) => !this.calc.hideField(id, this.getValue);
  }

  openModal = () => {
    this.setState({modalOpen: "complete"});
  };
  closeModal = () => {
    this.setState({modalOpen: undefined});
  };
  onPrint = () => {
    this.setState({printed: true});
    window.print();
  }
  onQuit = () => {
    this.setState({modalOpen: "restart"});
  }

  render() {
    document.title = "CLEAR-OP Summary";
    const {formValues, pageHistory, modalOpen} = this.state;
    return (
      <div className='Results centered'>
        <Modal
          isOpen={!!modalOpen}
          onRequestClose={this.closeModal}
          className="Modal"
        >
          {modalOpen === "complete" && <Complete onBack={this.closeModal} onQuit={this.onQuit} formValues={formValues} showField={this.showField} onPrint={this.onPrint} printed={this.state.printed}/>}
          {modalOpen === "restart" && <Restart onBack={this.closeModal} />}
        </Modal>
        {/* <div className="noprint"> */}
        <div className="PrintHead">
          <div>
            <Header />
            <h1>CLEAR-OP Summary</h1>
          </div>
          <div className="Stamp onlyprint">
            <p>Place Sticker Here</p>
          </div>
        </div>

        <ResultsArrayV1 flat={formValues} pageHistory={pageHistory} showField={this.showField} />

        <div className='lowerNav noprint'>
          <div>
            {this.props.onEdit && <button className="button" onClick={this.props.onEdit}>Edit</button>}
          </div>
          <div>
            <button className="button" onClick={this.openModal}>Complete Assessment</button>
          </div>
          <div>
            <div className="NavLinks">
              <a href="#questions">Questions?</a>
              <a href="#tips">Tips</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
