const SAVE_KEY = 'ttl_doa_preop_form';
const PAGE_SAVE_KEY = 'ttl_doa_preop_page';

/**
 * Save and Load form data from sessionStorage
 */
export default class Keystore {
  static loadPage() {
    return sessionStorage.getItem(PAGE_SAVE_KEY);
  }
  static savePage(page:string) {
    return sessionStorage.setItem(PAGE_SAVE_KEY, page);
  }
  static loadState() {
    try {
      const data = sessionStorage.getItem(SAVE_KEY);
      if (data) return JSON.parse(data);
    } catch (err) {
      console.warn(err);
    }
    return undefined;
  }
  static saveState(data:any) {
    try {
      sessionStorage.setItem(SAVE_KEY, JSON.stringify(data))
    } catch (err) {
      console.warn(err);
    }
  }
  static clearSaves() {
    sessionStorage.removeItem(SAVE_KEY);
    sessionStorage.removeItem(PAGE_SAVE_KEY)
  }
}
