import "./HighRisk.scss"

export default function HighRisk(props: {onBack:() => void}) {
  return (
    <div className='ModalContainer HighRisk'>
      <div className=''>
        <button className="button" onClick={props.onBack}>Back</button>
        <h2>NON-CARDIAC HIGH-RISK SURGERY LIST</h2>

        <div className="HighRiskList">
          <div>
            <h3>Neurosurgery</h3>
            <ul>
              <li>Craniotomy</li>
              <li>Craniectomy</li>
              <li>Meningeal Resection</li>
              <li>Pituitary or Pineal Gland Resection</li>
              <li>Intracranial Cerebral Vascular procedures</li>
            </ul>
          </div>

          <div>
            <h3>Thoracics </h3>
            <ul>
              <li>Lobectomy or Pneumonectomy</li>
              <li>Video-Assisted Thoracoscopic Surgery (VATS)</li>
              <li>Thoracoplasty</li>
              <li>Bronchial procedures</li>
              <li>Decortication of lung</li>
              <li>Pleural excision</li>
              <li>Mediastinal incision/procedure</li>
              <li>Esophagectomy</li>
            </ul>
          </div>

          <div>
            <h3>General Surgery</h3>
            <ul>
              <li>Spleen procedures</li>
              <li>Gastrectomy </li>
              <li>Duodenal incisions</li>
              <li>Small bowel resections</li>
              <li>Colon resections</li>
              <li>Diaphragmatic Hernia repair</li>
              <li>Hepatectomy</li>
              <li>Pancreatectomy</li>
              <li>Biliary Bypass</li>
            </ul>
          </div>

          <div>
            <h3>Orthopedic Surgery</h3>
            <ul>
              <li>Amputation of lower extremity</li>
              <li>Hip Disarticulation (Amputation)</li>
              <li>Hindquarter amputation </li>
            </ul>
          </div>
          <div>
            <h3>Vascular Surgery</h3>
            <ul>
              <li>Vascular Bypass</li>
              <li>Endarterectomy</li>
              <li>Aortic Surgery</li>
            </ul>
          </div>

          <div>
            <h3>Urology</h3>
            <ul>
              <li>Nephrectomy</li>
              <li>Cystectomy</li>
              <li>Urinary Bypass</li>
            </ul>
          </div>

          <div>
            <h3>ENT</h3>
            <ul>
              <li>Laryngectomy</li>
              <li>Tracheal incisions</li>
            </ul>
          </div>

          <div>
            <h3>Transplant Surgery</h3>
            <ul>
              <li>All Organ Transplants</li>
            </ul>
          </div>
          </div>
          <blockquote>
            <u>Adapted from:</u> eTable 4. Collection of 154 Operations Identified in the NIS as High Risk for Patients Age Less Than 65.
          </blockquote>
          <blockquote>
          Schwarze ML, Barnato AE, Rathouz PJ, et al. Development of a list of high-risk operations for patients 65 years and older. <i>JAMA Surg.</i> Published online February 18, 2015. doi:10.1001/jamasurg.2014.1819.
          </blockquote>
      </div>
    </div>
  );
}
