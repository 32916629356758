export default function GLP1(props: {onBack:() => void}) {
  return (
    <div className='ModalContainer GLP1-Note'>
      <div className=''>
        <button className="button" onClick={props.onBack}>Back</button>
        <h2>GLP-1 agonists</h2>
        <p>
          Guidelines for preoperative management of GLP-1 agonists are in evolution.  Patients may be taking these medications to treat obesity and/or diabetes.  Current evidence suggests increased perioperative concern secondary to significantly delayed gastric emptying, with potential aspiration risk.  If the medication is unable to be stopped for the required length of time preoperatively, inform patient that a discussion with the surgeon and anesthesiologist regarding risks/benefits of proceeding with or cancelling the elective surgery will occur.
        </p>
        <p>
          Examples of GLP-1 agonists include semaglutide (Ozempic®, Wegovy®), tirzepatide (Mounjaro®), dulaglutide (Trulicity®)
        </p>
      </div>
    </div>
  );
}
