export default function Questions(props:{onBack:()=>void}) {
  return <div className="ModalContainer Questions"><div>
    <div className="QContact">Contact:</div>
    <li>Brian Taylor MD, BSc, FRCPC, PGDipCU</li>
    <li>Anesthesiologist, Clinical Assistant Professor</li>
    <li>Provincial Department of Anesthesiology, Perioperative Medicine and Pain Management</li>
    <li>University of Saskatchewan College of Medicine</li>
    <li>Royal University Hospital</li>
    <li>103 Hospital Drive</li>
    <li>Saskatoon, Saskatchewan S7N 0W8, CANADA</li>
    <li>306-655-1183 (p)</li>
    <li>306-655-1279 (f)</li>
    <li>brian.taylor@usask.ca</li>
    <div className="BackContainer">
      <button className="button" onClick={props.onBack}>Back</button>
    </div>
  </div></div>
}
