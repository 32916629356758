import { Fragment } from "react";
import { FormItemTypes, GridColumnItem } from "./data/form";

/**
 * Render the value of a form item
 * @param props.item Form item
 * @param props.flat Form values
 * @returns JSX.Element
 */
export default function ResultsValue(props:{item:FormItemTypes, flat:{[key:string]: string|string[]|undefined}}) {
  const {item, flat} = props;
  let value = flat[item.id];
  switch (item.type) {
    case 'CHECKBOX':
      const arrVals = (flat[item.id] || []) as string[];
      const otherVal = flat[item.id + '-Other'] as string|undefined;
      let body = 'N/A';
      const arr = arrVals.filter(x => x!== "Other");
      if (otherVal) {
        arr.push("Other: " + otherVal);
      }
      if (arr.length) {
        body = arr.join(", ");
      }
      return (
        <div className='ResultsItemCheckbox'>
          {item.resultTitle && <>
            <div dangerouslySetInnerHTML={{__html: item.resultTitle}} />
            <div className='ResultsSeparator' />
          </>}
          <div style={{fontWeight: item.boldOptions ? 700 : 500}}>{body}</div>
        </div>
      );
    case 'FIELDS':
      if (item.customRender) {
        let renderText = item.customRender;
        for (let field of item.questions) {
          if (typeof field === 'object') {
            const newKey = '{'+field.key+'}'
            let keyValue = flat[item.id + '-' + field.key];
            if (typeof keyValue === "string" && field.type === "month") {
              keyValue = keyValue.slice(0, -3);
            }
            renderText = renderText.replaceAll(newKey, typeof keyValue === "string" ? keyValue : '');
          }
        }
        return <div id={item.id} dangerouslySetInnerHTML={{__html: renderText}}></div>
      }
      return <div className='ResultsItemGrid'>
        {item.resultTitle && <h4 dangerouslySetInnerHTML={{__html: item.resultTitle}} />}
        {item.questions.map((question) => {
          if (typeof question === 'string') {
            question = {key: question, title: question}
          }
          const value = flat[item.id + '-' + question.key];
          if (typeof value === 'string' && value.trim().length > 0) {
            const separator = item.resultSeparator ?? item.resultPrependTitles ? ': ' : ' ';

            return <div key={question.key} className='FieldItemResult' dangerouslySetInnerHTML={{
              __html: (question.resultTitle || (item.resultPrependTitles ? question.title : ''))
                + (separator)
                + (value || '')
              }} />;
          } else {
            return null;
          }
        })}
      </div>
    case 'GRID':
      return null;
    case 'BOOLEAN_GRID':
      const values:{[key:string]:GridColumnItem} = {};
      item.columns.forEach((col) => {
        values[col.key] = col;
      });
      return (
        <div className='ResultsItemGrid'>
          {item.rows.map((row, index) => {
            let value = flat[item.id + '-' + row];
            if (typeof value !== 'string') {
              value = '';
            }
            const details = flat[item.id + '-' + row + '-Details']
            const text = values[value].resultTitle ?? values[value].title;
            return (
              <Fragment key={index}>
                <div dangerouslySetInnerHTML={{__html: `${row}:`}} />
                <div dangerouslySetInnerHTML={{__html: text + (details ? '<br>' + details : '')}} />
              </Fragment>
            )
          })}
        </div>
      );
    case 'BOOLEAN':
    // @ts-ignore eslint-disable-next-line no-fallthrough
    case 'BOOLEAN_DETAILS':
      if (item.resultTitle) {
        if (typeof item.resultTitle === "string") {
          value = item.resultTitle;
        } else {
          value = item.resultTitle[value as string] || "";
        }
      } else {
        if (value === 'False') {
          value = item.falseTitle || 'No';
        }
        else if (value === 'True') {
          value = item.trueTitle || 'Yes';
        }
        value = (item.title || "") + " " + value;
      }
      return <div dangerouslySetInnerHTML={{__html: `${value || 'N/A'}`}} />
    case 'RADIO':
      let title = value;
      for (let option of item.options) {
        if (typeof option !== "string" && (option?.value ?? option?.title) === value) {
          title = option.resultTitle ?? option.title;
        }
      }
      if (title === "Other" || title?.includes('-Other')) {
        title = flat[item.id + '-Other'];
      }
      return <div style={{fontWeight: item.boldOption ? 700 : 500}} dangerouslySetInnerHTML={{__html: `${item.resultTitle ?? ""} ${title || 'N/A'}`}} />
    case 'TEXTAREA':
      return (
        <>
          <div dangerouslySetInnerHTML={{__html: item.title || ""}} />
          <div className='ResultsSeparator' />
          <div dangerouslySetInnerHTML={{__html: `${value || 'N/A'}`}} />
        </>
      );
    case 'SECTION_HEADER':
    case 'PARAGRAPH':
    case 'PAGE_BREAK':
      return null;
    default:
      return null;
  }
}