export default function Acknowledgements(props:{onBack:()=>void}) {
  return <div className="ModalContainer Acknowledgements"><div>
    <div className="PossibleBy">Made possible by:</div>
    <li>Territorial software development agency</li>
    <li>Saskatoon City Hospital Foundation support</li>
    <li>Saskatchewan Health Authority PAC Working Group Committee</li>
    <li>University of Saskatchewan Provincial Department of Anesthesiology, Perioperative Medicine and Pain Management</li>
    <div className="BackContainer">
      <button className="button" onClick={props.onBack}>Back</button>
    </div>
  </div></div>
}
