import Footer from './components/Footer';
import Header from './components/Header';
import "./Start.scss";

export default function Start(props:{onStart: ()=>void}) {
  return (
    <div className="StartPage">
      <Header hideTitle />

      <div className='Start centered'>
        <div className='Title' >
          <h1>CLEAR-OP <div className='trademark'>&trade;</div></h1>
        </div>
        <h2><b>C</b>hoosing <b>L</b>ogic in <b>E</b>valuating, <b>A</b>ssigning <b>R</b>isk, and <b>O</b>ptimizing <b>P</b>atients</h2>
        <h4>* For adult patients &ge; age 17 undergoing elective, non-cardiac surgery</h4>
        <button className="button" onClick={props.onStart}>START</button>
        <div className="ModalLinks">
          <div>
            <a href="#acknowledgements">Acknowledgements</a>
            <a href="#questions">Questions?</a>
            <a href="#tips">Tips</a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}