import { Component } from 'react';
import FormItems from './FormItems';
import Results from './Results';
import Modal from 'react-modal';
import HighRisk from './modals/HighRisk';
import Acknowledgements from './modals/Acknowledgements';
import Questions from './modals/Questions';
import Tips from './modals/Tips';
import Start from './Start';
import Keystore from './KeyStore';
import GLP1 from './modals/GLP1Note';

type AppState = {
  appPage: string,
  modal: string,
}
const modals: {[key:string]:boolean} = {
  "#high-risk": true,
  "#acknowledgements": true,
  "#questions": true,
  "#tips": true,
  "#glp1": true,
};
export default class App extends Component<{}, AppState> {
  constructor(props:any) {
    super(props);

    this.state = {
      appPage: Keystore.loadPage() || 'start',
      modal: window.location.hash,
    };
  }
  componentDidMount = () => {
    window.addEventListener("hashchange", this.hashChange);
  }
  componentWillUnmount = () => {
    window.removeEventListener("hashchange", this.hashChange);
  }

  gotoStartPage = () => {
    Keystore.savePage('start');
    this.setState({appPage:'start'});
  }

  gotoResultPage = () => {
    Keystore.savePage('result');
    this.setState({appPage:'result'});
  }
  gotoFormPage = () => {
    Keystore.savePage('form');
    this.setState({appPage:'form'});
  }

  hashChange = (e:{target:any}) => {
    const target = e.target as Window;
    this.setState({ modal: target.location.hash});
  }
  closeModal = () => {
    window.location.hash = "";
    this.setState({modal: ""})
  };
  
  render() {
    const {appPage} = this.state;
    let content = <div>An error occurred</div>
    switch (appPage) {
      case 'start':
        content = <Start onStart={this.gotoFormPage} />
        break;
      case 'form':
        content =  <FormItems onSubmit={this.gotoResultPage} onStart={this.gotoStartPage}/>
        break;
      case 'result':
        content = <Results onEdit={this.gotoFormPage}/>
        break;
    }
    return <div>
      <Modal
        isOpen={!!modals[this.state.modal]}
        onRequestClose={this.closeModal}
        ariaHideApp={false}
        className={"Modal"}
      >
        {this.state.modal === "#high-risk" && <HighRisk onBack={this.closeModal} />}
        {this.state.modal === "#acknowledgements" && <Acknowledgements onBack={this.closeModal} />}
        {this.state.modal === "#questions" && <Questions onBack={this.closeModal} />}
        {this.state.modal === "#tips" && <Tips onBack={this.closeModal} />}
        {this.state.modal === "#glp1" && <GLP1 onBack={this.closeModal} />}
      </Modal>
      {content}
    </div>
  }
};