import React from 'react';
import pkg from "../../package.json";
import './Footer.scss';

export default function Footer() {
  return (
    <footer>
      <div className="centered">
        <div>
          © {new Date().getFullYear()} University of Saskatchewan Provincial Department of Anesthesiology, Perioperative Medicine, and Pain Management
        </div>
        <div hidden>v{pkg.version}</div>
      </div>
    </footer>
  );
}
