import Keystore from "../KeyStore";
import "./Restart.scss";

function onQuit() {
  Keystore.clearSaves();
  window.location.reload();
}

export default function Restart(props: {onBack: ()=>void}) {
  return (
    <div className='ModalContainer Restart'>
      <div>
        <h3>
          Are you sure you want to QUIT CLEAR-OP?
        </h3>
        <p>Information is not saved, all inputs will be deleted.</p>
        <div className="RestartNav">
          <button type="button" className="button" onClick={props.onBack}>Cancel Quit</button>
          <button type="button" className="button" onClick={onQuit}>Confirm Quit</button>
        </div>
      </div>
    </div>
  );
}