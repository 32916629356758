import React from 'react'

export default function PrintPageBreak(props:{className?:string}) {
  return (
    <div className={props.className}>
      {/* <p style={{pageBreakAfter: 'always'}}>&nbsp;</p> */}
      <p style={{pageBreakBefore: 'always'}}>&nbsp;</p>
    </div>
  )
}
