import React, {ChangeEvent, FormEvent} from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import FormItem from './FormItem';
import {FormItemTypes, FormValue, FormValues, Navigatable} from './data/form';
import data from './data/DOAForm';
import calculationsArray from './data/DOACalc';
import FormCalculation from "./Calculation";
import Keystore from './KeyStore';
import './FormItems.scss';

type FormItemsState = {
  page:number,
  pages:number[],
  pageHistory: number[],
  createDate: string,
  /** Store form results within pages,
   * if a page is no longer part of the history ignore
   */
  fieldPage: {[fieldid:string]:number}
  formValues: FormValues,
}


export default class FormItems extends React.Component<{onSubmit?:Function, onStart:Function}, FormItemsState> {
  calculations: FormCalculation;
  constructor(props:any) {
    super(props);
    const pages:number[] = [];
    const fieldPage:{[key:string]: number} = {};
    data.forEach((item, index) => {
      if (item.type === 'PAGE_BREAK') {
        pages.push(index);
      } else {
        fieldPage[item.id] = pages.length - 1;
      }
    });
    const storedState = Keystore.loadState();
    this.state = {
      page: -1,
      pageHistory: [],
      createDate: new Date().toISOString(),
      pages,
      fieldPage,
      formValues: {},
    };
    this.calculations = new FormCalculation(calculationsArray);
    if (process.env.NODE_ENV === 'development') {
      // @ts-ignore
      window.FORMITEMS = this;
    }
    if (storedState) {
      try {
        if (storedState.pages.length !== pages.length) {
          this.clearData();
          return;
        }
        for (let i=0; i<pages.length; i++) {
          if (pages[i] !== storedState.pages[i]) {
            this.clearData();
            return;
          }
        }
        // remove cur page from history if it was previous page
        if (storedState.pageHistory.slice(-1)[0] === storedState.page) {
          storedState.pageHistory = storedState.pageHistory.slice(0, -1);
        }
        this.state = {
          ...this.state,
          ...storedState,
        };
      } catch (err) {
        console.warn(err);
      }
    }
  }

  beforeUnload = (e:BeforeUnloadEvent) => {
    this.saveData();
    e.preventDefault()
    e.returnValue = ''
  }
  saveData = () => {
    Keystore.saveState(this.state);
  };
  clearData = () => {
    Keystore.clearSaves();
  }
  submitData = () => {
    const {pageHistory, page} = this.state;
    if (pageHistory[pageHistory.length - 1] !== page) {
      pageHistory.push(page);
    }
    this.saveData();
    this.props.onSubmit?.();
  }

  componentDidMount = () => {
    window.addEventListener('beforeunload', this.beforeUnload);
    window.addEventListener('keyup', this.keyboardNavigation);
    window.addEventListener('keydown', this.ignoreArrowFields);
  };
  closeListener = () => {
    window.removeEventListener('beforeunload', this.beforeUnload);
    window.removeEventListener('keyup', this.keyboardNavigation);
    window.removeEventListener('keydown', this.ignoreArrowFields);
  }
  componentWillUnmount = () => {
      this.closeListener();
  };

  ignoreArrowFields = (event:KeyboardEvent) => {
    // @ts-ignore
    // if (event.target?.nodeName === 'INPUT') {
    //   return;
    // }
    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
      event.preventDefault();
    }
  }

  keyboardNavigation = (event:KeyboardEvent) => {
    // @ts-ignore
    // if (event.target?.nodeName === 'INPUT') {
    //   return;
    // }
    if (event.key === "ArrowLeft") {
      event.preventDefault();
      event.stopPropagation();
      this.handleBack();
    } else if (event.key === "ArrowRight") {
      const {pages, page} = this.state;
      event.preventDefault();
      event.stopPropagation();
      // @ts-ignore
      const form = document.CLEAROP as HTMLFormElement|undefined;
      const valid = form?.checkValidity();
      if (!valid) {
        form?.reportValidity();
        return;
      }
      if (pages[page + 1] != null) {
        this.handleNext();
      } else {
        this.submitData();
      }
    } else {
      return;
    }
  };


  setCalcValue = (id:string, value:FormValue) => {
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.formValues[id] = value;
  };
  setValue = (
    id:string,
    newValue:FormValue,
    page: number,
    newValues:FormValues = {}
  ) => {
    const newState = {
      ...this.state,
      formValues: {
        ...this.state.formValues,
        ...newValues,
        [id]: newValue,
      },
      fieldPage: {
        ...this.state.fieldPage,
        [id]: page,
      }
    };
    console.log(newState);
  }

  handleChangeEvent = (
    e: ChangeEvent<HTMLInputElement|HTMLTextAreaElement>|{target:{id:string,name:string,value:string,placeholder?:string}},
    isSingleQuestion: boolean,
    item: FormItemTypes
  ) => {
    const {name, value} = e.target;
    let newValue:string|string[] = value;
    if (e.target.placeholder === 'A0A0A0') {
      newValue = newValue.toUpperCase();
    }
    // store checkbox as array of values
    if ('checked' in e.target && e.target.type === 'checkbox') {
      const {formValues} = this.state;
      newValue = (formValues?.[name] || []) as string[];
      if (e.target.checked && !newValue.includes(value)) {
        newValue.push(value)
      } else if (!e.target.checked && newValue.includes(value)) {
        newValue = newValue.filter((selected) => selected !== value);
      }
    }
    let {formValues, page, fieldPage} = this.state;
    formValues = {
      ...this.state.formValues,
      [name]: newValue,
    }
    if (name.slice(-6) === "-Other") {
      const parentKey = name.slice(0, -6);
      if (item.type === "CHECKBOX") {
        const arr = (formValues[parentKey] ?? []) as string[];
        if (!arr.includes("Other")) {
          arr.push("Other");
        }
        formValues[parentKey] = arr;
      } else if (item.type === "RADIO") {
        formValues[parentKey] = "Other";
      }
    }
    const getValue = (fieldId:string) => {
      const itemId = fieldId.split("-")[0];
      const item_page = fieldPage[itemId];
      return this.state.pageHistory.includes(item_page) || page === item_page
        ? formValues[fieldId]
        : undefined;
    };
    const setValue = (fieldId:string, value:FormValue) => {
      formValues[fieldId] = value;
      fieldPage[fieldId] = page
    };
    this.calculations.checkEventChange(name, getValue, setValue);
    this.setState({
      formValues: {
        ...formValues,
      },
      fieldPage: {
        ...fieldPage,
        [name]: page,
      }
    }, () => {
      if (e.target.id.endsWith("-Details")) {
        // Ignore details for auto handling next page
      } else if (isSingleQuestion) {
        if (item.type === 'BOOLEAN') {
        this.handleNext();
        } else if (item.type === 'BOOLEAN_DETAILS' && newValue !== item.detailsOn) {
          this.handleNext();
        } else if (item.type === 'RADIO') {
          for (let x of item.options) {
            if (typeof x === "object" && (x.value ?? x.title) === newValue && x.triggerNext) {
              this.handleNext();
              return;
            }
          }
          return;
        }
      }
    });
  }

  /**
   * Returns index of page in pages array to navigate to
   * @param nav Navigatable object
   * @returns index of page in pages array
   */
  getNavigation(nav:Navigatable|undefined) {
    if (nav?.pageNavigationType === 'GO_TO_PAGE' && nav.goToPage) {
      for (let i=0; i<this.state.pages.length; i++) {
        const pageIndex = this.state.pages[i];
        if (data[pageIndex].id === nav.goToPage) {
          return i;
        }
      };
    }
  }
    
  get pageBounds() {
    const {pages, page} = this.state;
    return [pages[page], pages[page + 1]];
  }
  get nextPage() {
    const {pages, page, formValues} = this.state;
    const items = data.slice(...this.pageBounds) as FormItemTypes[];
    for (let item of items) {
      const curValue = formValues?.[item.id];
      if (item.type === 'BOOLEAN' || item.type === 'BOOLEAN_DETAILS') {
        const onFalseNav = this.getNavigation(item.onFalse);
        if (onFalseNav != null && curValue === 'False') {
          return onFalseNav
        }
        const onTrueNav = this.getNavigation(item.onTrue);
        if (onTrueNav != null && curValue === 'True') {
          return onTrueNav;
        }
      } else if (item.type === 'RADIO') {
        for (let option of item.options) {
          if (typeof option === 'object' && (option.value || option.title) === curValue)
            return this.getNavigation(option);
        };
      }
    }
    return Math.min(page + 1, pages.length - 1);
  }
  handleNext = () => {
    const {pageHistory, page} = this.state;
    window.scrollTo(0, 0);
    let newPage = this.nextPage ?? page + 1;
    let pageItem = data[this.state.pages[newPage]];
    
    while (this.calculations.skipPage(pageItem.id, (id) => this.state.formValues[id])){
      newPage = newPage + 1;
      pageItem = data[this.state.pages[newPage]];
    }
    
    this.setState({
      pageHistory: [...pageHistory, page],
      page: newPage,
    }, () => {
      document.getElementsByTagName('input')[0]?.focus();
      this.saveData();
    });
  };
  handleBack = () => {
    const {pageHistory} = this.state;
    window.scrollTo(0, 0);
    let page = pageHistory.pop();
    if (page === undefined) {
      this.saveData();
      this.props.onStart();
      return;
    }
    this.setState({
      pageHistory,
      page: page
    })
  };
  handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    const {pages, page} = this.state;
    e.preventDefault();
    e.stopPropagation();
    if (pages[page + 1] != null) {
      this.handleNext();
    } else {
      this.submitData();
    }
  }
  restart = (e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (window.confirm("Are you sure you wish to restart? This will delete all present data.")) {
      this.closeListener();
      this.clearData();
      window.location.reload();
    }
  }

  getValue = (id:string) => {
    const item_page = this.state.fieldPage[id];
    return this.state.pageHistory.includes(item_page) || this.state.page === item_page
      ? this.state.formValues[id]
      : undefined;
  }

  render() {
    const [pageStart, pageEnd] = this.pageBounds;
    const items = data.slice(...this.pageBounds) as FormItemTypes[];
    if (pageStart > 0 && data[pageStart + 1].type !== "SECTION_HEADER") {
      let sectionIndex = pageStart - 1;
      let prevItem = data[sectionIndex];
      while(prevItem.type !== "SECTION_HEADER") {
        sectionIndex--;
        prevItem = data[sectionIndex];
      }
      items.unshift(prevItem);
    }
    const questionCount = items.reduce((rValue: number, item) => {
      // @ts-ignore
      return rValue + !['PAGE_BREAK', 'SECTION_HEADER', 'PARAGRAPH', 'VALUES'].includes(item.type);
    }, 0);

    return (
      <div className='Container'>
        <Header />
        <form id="CLEAROP" name="CLEAROP" className='FormItems' onSubmit={this.handleSubmit}>
          <div className='centered'>
            {/* <div style={{float: "right"}}>Page {this.state.page+2}</div> */}
            {items.map((item) => {
              const isSingleQuestion = questionCount === 1;
              if (this.calculations.hideField(item.id, this.getValue, this.setCalcValue)) {
                return null;
              }
              return (
                <FormItem
                  key={item.id}
                  item={item as FormItemTypes}
                  onChange={e =>  this.handleChangeEvent(e, isSingleQuestion, item)}
                  values={this.state.formValues ?? {}}
                />
              );
            })}
            <div className='FormNavigation'>
              <div>
                <button type='button' onClick={this.handleBack} className='button'>Back</button>
                <input className='button' type='submit' value={pageEnd == null ? 'Finish' : 'Next'} />
              </div>
              <div className="FormTips ModalLinks">
                <a href="#tips">Tips</a>
              </div>
              <div>
                <button onClick={this.restart} className='button'>Restart</button>
              </div>
            </div>
          </div>
        </form>
        <Footer />
      </div>
    );
  }
}
